// TermManager.tsx

import React, { useState, useReducer, useEffect, useCallback, useRef, memo } from 'react';
import {
    Box,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material';

import { Add as AddIcon, Autorenew as AutorenewIcon } from '@mui/icons-material';

import TermCard from './term-card';

import { WorkflowTermStatus, WorkflowTermSelectionStatus } from './term';
import { WorkflowTopic, WorkflowTerm} from '@mudlark/common'

import { useSSE } from './sse-context';
import { getAPIClient } from './client-id';

import { useSnackbar } from './snackbar-context'

// Define Action Types for the Reducer
type Action =
    | { type: 'ADD_TERM'; payload: { term: string } }
    | { type: 'GENERATE_TERMS_START' }
    | { type: 'GENERATE_TERMS_SUCCESS'; payload: { terms: string[] } }
    | { type: 'GENERATE_TERMS_FAILURE'; payload: { error: string } }
    | { type: 'ADD_TOPICS'; payload: { term: string; topics: WorkflowTopic[] } }
    // | { type: 'UPDATE_TERM_STATUS'; payload: { term: string; status: WorkflowTermStatus; live?: boolean, volume?: number, trendDirection?: TrendDirection } }
    | { type: 'SET_TERM_SELECTION_STATUS'; payload: { term: string; selectionStatus: WorkflowTermSelectionStatus } }
    | { type: 'UPDATE_TOPIC_RATING'; payload: { term: string; mid: string; relevance_score: number } }
    | { type: 'SET_TOPIC_EXCLUDED_STATUS'; payload: { term: string; mid: string; isExcluded: boolean } }
    | { type: 'SET_ERROR'; payload: { term: string; error: string } }
    | { type: 'TOPICS_FOUND'; payload: { term: string; topics: WorkflowTopic[] } }
    | { type: 'TOPIC_SEARCH_COMPLETE'; payload: { term: string } }
    | { type: 'TOPICS_RATED'; payload: { term: string; topics: { mid: string; relevance_score: number }[] } }
    | { type: 'NO_TOPICS_MATCH'; payload: { term: string } }
    | { type: 'TOPICS_RATING_COMPLETE'; payload: { term: string } }
    | { type: 'INTEREST_PROBED'; payload: { term: string; success: boolean } }
    | { type: 'INTEREST_PROBE_COMPLETE'; payload: { terms: string[] } }
    | { type: 'INTEREST_PROBE_FAILED'; payload: { terms: string[] } }
    | { type: 'MARK_ALL_TERMS_COMPLETED' } // New action type
    | { type: 'SET_INITIAL_TERMS'; payload: { [term: string]: WorkflowTerm } }; // Updated payload type

// Define the initial state for the reducer
interface State {
    terms: WorkflowTerm[];
    loading: boolean;
    error: string | null;
}

const initialState: State = {
    terms: [],
    loading: false,
    error: null,
};

// Reducer function to manage the state
function reducer(state: State, action: Action): State {
    switch (action.type) {
    case 'ADD_TERM':
        // Trim and lowercase the payload term
        const term = action.payload.term.trim().toLowerCase();

        // Prevent adding duplicate terms (case-insensitive)
        if (state.terms.find((t) => t.term.toLowerCase() === term)) {
            return state;
        }
        return {
            ...state,
            terms: [
                ...state.terms,
                {
                    term: term,
                    status: WorkflowTermStatus.NewlyAdded,
                    topics: {},
                    selectionStatus: WorkflowTermSelectionStatus.UserAdded,
                    hasUsableTopics: false,
                },
            ],
        };

    case 'GENERATE_TERMS_START':
        return {
            ...state,
            loading: true,
            error: null,
        };

    case 'GENERATE_TERMS_SUCCESS':
        const newTerms = action.payload.terms
            .map(term => term.trim().toLowerCase())
            .filter((term) => !state.terms.find((t) => t.term.toLowerCase() === term))
            .map((term) => ({
                term,
                status: WorkflowTermStatus.NewlyAdded,
                topics: {},
                selectionStatus: WorkflowTermSelectionStatus.Suggested,
                hasUsableTopics: false,
            }));
        return {
            ...state,
            terms: [...state.terms, ...newTerms],
            loading: false,
            error: null,
        };

    case 'GENERATE_TERMS_FAILURE':
        return {
            ...state,
            loading: false,
            error: action.payload.error,
        };

    case 'ADD_TOPICS':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        topics: {
                            ...t.topics,
                            ...action.payload.topics.reduce((acc, topic) => {
                                acc[topic.mid] = topic;
                                return acc;
                            }, {} as { [mid: string]: WorkflowTopic }),
                        },
                        status: WorkflowTermStatus.ReceivedTopics,
                        hasUsableTopics: action.payload.topics.some(topic => topic.live === true),
                    }
                    : t
            ),
        };

    // case 'UPDATE_TERM_STATUS':
    //     return {
    //         ...state,
    //         terms: state.terms.map((t) => {
    //             if (t.term === action.payload.term) {
    //                 // Update term status
    //                 return {
    //                     ...t,
    //                     status: action.payload.status,
    //                     live: action.payload.live != undefined ? action.payload.live : t.live,
    //                     volume: action.payload.volume != undefined ? action.payload.volume : t.volume,
    //                     trendDirection: action.payload.trendDirection != undefined ? action.payload.trendDirection : t.trendDirection,
    //                 };
    //             }
    //             return t;
    //         }),
    //     };

    case 'SET_TERM_SELECTION_STATUS':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? { ...t, selectionStatus: action.payload.selectionStatus }
                    : t
            ),
        };

    case 'UPDATE_TOPIC_RATING':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        topics: {
                            ...t.topics,
                            [action.payload.mid]: {
                                ...t.topics[action.payload.mid],
                                relevance_score: action.payload.relevance_score,
                            },
                        },
                    }
                    : t
            ),
        };

    case 'SET_TOPIC_EXCLUDED_STATUS':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        topics: {
                            ...t.topics,
                            [action.payload.mid]: {
                                ...t.topics[action.payload.mid],
                                isExcluded: action.payload.isExcluded,
                            },
                        },
                    }
                    : t
            ),
        };

    case 'SET_ERROR':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term ? { ...t, status: WorkflowTermStatus.Error } : t
            ),
            error: action.payload.error,
        };

    case 'TOPICS_FOUND':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        topics: {
                            ...t.topics,
                            ...action.payload.topics.reduce((acc, topic) => {
                                acc[topic.mid] = topic;
                                return acc;
                            }, {} as { [mid: string]: WorkflowTopic }),
                        },
                    }
                    : t
            ),
        };

    case 'TOPIC_SEARCH_COMPLETE':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        status: WorkflowTermStatus.TopicsSearchComplete,
                    }
                    : t
            ),
        };

    case 'TOPICS_RATED':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        topics: {
                            ...t.topics,
                            ...action.payload.topics.reduce((acc, topicRating) => {
                                if (t.topics[topicRating.mid]) {
                                    acc[topicRating.mid] = {
                                        ...t.topics[topicRating.mid],
                                        relevance_score: topicRating.relevance_score,
                                    };
                                }
                                return acc;
                            }, {} as { [mid: string]: WorkflowTopic }),
                        },
                    }
                    : t
            ),
        };

    case 'NO_TOPICS_MATCH':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        hasUsableTopics: false,
                    }
                    : t
            ),
        };

    case 'TOPICS_RATING_COMPLETE':
        return {
            ...state,
            terms: state.terms.map((t) =>
                t.term === action.payload.term
                    ? {
                        ...t,
                        status: WorkflowTermStatus.TopicsRatingComplete,
                    }
                    : t
            ),
        };

    case 'INTEREST_PROBED':
        return {
            ...state,
            terms: state.terms.map((t) => {
                let updatedTerm = { ...t };
                let found = false;

                // Check if the payload.term matches the term's 'term' property
                if (t.term === action.payload.term) {
                    updatedTerm.live = action.payload.success;

                    console.log('Interest probed:', action.payload.term, 'setting live to: ', action.payload.success);
                    found = true;
                }

                // If not found, search within the topics
                if (!found) {
                    const topics = { ...t.topics };
                    Object.keys(topics).forEach((mid) => {
                        if (mid === action.payload.term) {
                            console.log('Interest probed:', mid, 'setting live to: ', action.payload.success);

                            topics[mid] = {
                                ...topics[mid],
                                live: action.payload.success,
                            };
                            found = true;
                        }
                    });
                    updatedTerm.topics = topics;
                }

                // Update hasUsableTopics based on live topics
                const hasLiveTopic = Object.values(updatedTerm.topics).some(topic => topic.live === true);
                updatedTerm.hasUsableTopics = hasLiveTopic || updatedTerm.live === true;

                return updatedTerm;
            }),
        };

    case 'INTEREST_PROBE_COMPLETE':
        return {
            ...state,
            terms: state.terms.map((t) => {
                // If the term is in the payload, update its live status to false if not already set
                if (action.payload.terms.includes(t.term)) {
                    const updatedTerm = { ...t };
                    if (updatedTerm.live === undefined) {
                        updatedTerm.live = false;
                    }

                    // Update hasUsableTopics
                    const hasLiveTopic = Object.values(updatedTerm.topics).some(topic => topic.live === true);
                    updatedTerm.hasUsableTopics = hasLiveTopic || updatedTerm.live === true;

                    return updatedTerm;
                }
                // Update topics' live status
                const updatedTopics = { ...t.topics };
                action.payload.terms.forEach((termMid) => {
                    if (updatedTopics[termMid] && updatedTopics[termMid].live === undefined) {
                        updatedTopics[termMid] = {
                            ...updatedTopics[termMid],
                            live: false,
                        };
                    }
                });

                // Update hasUsableTopics
                const hasLiveTopic = Object.values(updatedTopics).some(topic => topic.live === true);
                return {
                    ...t,
                    topics: updatedTopics,
                    hasUsableTopics: hasLiveTopic || t.live === true,
                };
            }),
        };

    case 'INTEREST_PROBE_FAILED':
        // Handle failure by setting live status to false if not already set
        return {
            ...state,
            terms: state.terms.map((t) => {
                if (action.payload.terms.includes(t.term)) {
                    const updatedTerm = { ...t };
                    if (updatedTerm.live === undefined) {
                        updatedTerm.live = false;
                    }

                    // Update hasUsableTopics
                    const hasLiveTopic = Object.values(updatedTerm.topics).some(topic => topic.live === true);
                    updatedTerm.hasUsableTopics = hasLiveTopic || updatedTerm.live === true;

                    return updatedTerm;
                }
                // Update topics' live status
                const updatedTopics = { ...t.topics };
                action.payload.terms.forEach((termMid) => {
                    if (updatedTopics[termMid] && updatedTopics[termMid].live === undefined) {
                        updatedTopics[termMid] = {
                            ...updatedTopics[termMid],
                            live: false,
                        };
                    }
                });

                // Update hasUsableTopics
                const hasLiveTopic = Object.values(updatedTopics).some(topic => topic.live === true);
                return {
                    ...t,
                    topics: updatedTopics,
                    hasUsableTopics: hasLiveTopic || t.live === true,
                };
            }),
        };

    case 'MARK_ALL_TERMS_COMPLETED':
        return {
            ...state,
            terms: state.terms.map((t) => ({
                ...t,
                status: WorkflowTermStatus.Complete,
            })),
        };

    case 'SET_INITIAL_TERMS':
        return {
            ...state,
            terms: Object.values(action.payload), // Convert terms object to array
        };

    default:
        return state;
    }
}

// TermManager Component
interface TermManagerProps {
    workflowId: string;
    initialSearchTerms?: { [term: string]: WorkflowTerm } | null; // Updated type
    onTermsAdded?: (newTerms: string[]) => void;
    triggerTopicRenewal: (termName: string) => Promise<void>; // New prop for auto-renew
    onProcessComplete?: (canProceed: boolean) => void; // New prop to notify parent
}

const TermManager: React.FC<TermManagerProps> = ({ workflowId, initialSearchTerms = null, onTermsAdded, triggerTopicRenewal, onProcessComplete }) => {
    const subscribe = useSSE();

    const { showSnackbar } = useSnackbar(); // Access the Snackbar context

    const [state, dispatch] = useReducer(reducer, initialState);
    const [currentSearchTerm, setCurrentSearchTerm] = useState<string>('');
    const [isGenerating, setIsGenerating] = useState<boolean>(false);

    // Ensure the state is only initialized once
    const initializedRef = useRef(false);

    useEffect(() => {
        if (!initializedRef.current && initialSearchTerms) {
            initializedRef.current = true;
            // Dispatch an action to set the initial terms
            dispatch({ type: 'SET_INITIAL_TERMS', payload: initialSearchTerms });
        }
    }, [initialSearchTerms, dispatch]);

    // Function to handle adding a new term
    const handleAddTerm = () => {
        const trimmedTerm = currentSearchTerm.trim();
        if (trimmedTerm === '') {
            showSnackbar('Search term cannot be empty.', 'error'); // Use parent snackbar
            return;
        }
        const existingTerms = state.terms.map(t => t.term.toLowerCase());
        if (!existingTerms.includes(trimmedTerm.toLowerCase())) {
            dispatch({ type: 'ADD_TERM', payload: { term: trimmedTerm } });
            onTermsAdded?.([trimmedTerm]); // Call callback with the new term
            showSnackbar(`Term "${trimmedTerm}" added successfully.`, 'success');
        } else {
            showSnackbar(`Term "${trimmedTerm}" already exists.`, 'info'); // Notify duplicate
        }
        setCurrentSearchTerm('');
    };

    // Function to handle generating terms via API
    const handleGenerateTerms = async () => {
        setIsGenerating(true);
        dispatch({ type: 'GENERATE_TERMS_START' });
        try {
            const response = await getAPIClient().post(
                `/api/v1/workflow/${workflowId}/generate-terms`,
                {},
            );
            const { search_terms } = response.data;
            if (Array.isArray(search_terms) && search_terms.length > 0) {
                const newTerms = search_terms.filter(term => 
                    !state.terms.find(t => t.term.toLowerCase() === term.toLowerCase())
                );
                if (newTerms.length > 0) {
                    dispatch({ type: 'GENERATE_TERMS_SUCCESS', payload: { terms: newTerms } });
                    onTermsAdded?.(newTerms); // Call callback with the new terms
                    showSnackbar('Search terms generated successfully.', 'success');
                } else {
                    dispatch({
                        type: 'GENERATE_TERMS_FAILURE',
                        payload: { error: 'No new search terms were generated.' },
                    });
                    showSnackbar('No new search terms were generated.', 'info');
                }
            } else {
                dispatch({
                    type: 'GENERATE_TERMS_FAILURE',
                    payload: { error: 'No search terms were generated.' },
                });
                showSnackbar('No search terms were generated.', 'info');
            }
        } catch (error: any) {
            console.error('Error generating search terms:', error);
            dispatch({
                type: 'GENERATE_TERMS_FAILURE',
                payload: { error: 'Failed to generate search terms.' },
            });
            showSnackbar('Failed to generate search terms.', 'error');
        } finally {
            setIsGenerating(false);
        }
    };

    // Function to toggle pinning a term
    const togglePinTerm = (term: string) => {
        const termObj = state.terms.find((t) => t.term === term);
        if (termObj) {
            if (termObj.selectionStatus === WorkflowTermSelectionStatus.Suggested) {
                dispatch({
                    type: 'SET_TERM_SELECTION_STATUS',
                    payload: { term, selectionStatus: WorkflowTermSelectionStatus.Pinned },
                });
                showSnackbar(`Term "${term}" pinned.`, 'success');
            } else if (termObj.selectionStatus === WorkflowTermSelectionStatus.Pinned) {
                dispatch({
                    type: 'SET_TERM_SELECTION_STATUS',
                    payload: { term, selectionStatus: WorkflowTermSelectionStatus.Suggested },
                });
                showSnackbar(`Term "${term}" unpinned.`, 'success');
            } else {
                // Pinning only makes sense for suggested terms
                showSnackbar(`Cannot pin term "${term}".`, 'error');
            }
        }
    };

    // Function to toggle excluding a term
    const toggleExcludeTerm = (term: string) => {
        const termObj = state.terms.find((t) => t.term === term);
        if (termObj) {
            if (termObj.selectionStatus === WorkflowTermSelectionStatus.Suggested) {
                dispatch({
                    type: 'SET_TERM_SELECTION_STATUS',
                    payload: { term, selectionStatus: WorkflowTermSelectionStatus.Excluded },
                });
                showSnackbar(`Term "${term}" excluded.`, 'success');
            } else if (termObj.selectionStatus === WorkflowTermSelectionStatus.Excluded) {
                dispatch({
                    type: 'SET_TERM_SELECTION_STATUS',
                    payload: { term, selectionStatus: WorkflowTermSelectionStatus.Suggested },
                });
                showSnackbar(`Term "${term}" included.`, 'success');
            } else {
                // Excluding only makes sense for suggested terms
                showSnackbar(`Cannot exclude term "${term}".`, 'error');
            }
        }
    };

    // Function to update topic rating
    const handleTopicRating = (term: string, mid: string, relevance_score: number) => {
        dispatch({
            type: 'UPDATE_TOPIC_RATING',
            payload: { term, mid, relevance_score },
        });
    };

    // Function to toggle topic exclusion
    const toggleTopicExcludedStatus = (term: string, mid: string) => {
        const termObj = state.terms.find((t) => t.term === term);
        if (termObj) {
            const topic = termObj.topics[mid];
            if (topic) {
                dispatch({
                    type: 'SET_TOPIC_EXCLUDED_STATUS',
                    payload: { term, mid, isExcluded: !topic.isExcluded },
                });
            }
        }
    };

    useEffect(() => {
        const handleMessage = (message: { event: string, update: any }) => {
            try {
                const { event, update } = message;

                switch (event) {
                case 'topic-generated':
                    dispatch({
                        type: 'ADD_TOPICS',
                        payload: { term: update.term, topics: update.topics },
                    });
                    showSnackbar(`Topics generated for "${update.term}".`, 'success');
                    break;

                case 'interest-probed':
                    dispatch({
                        type: 'INTEREST_PROBED',
                        payload: {
                            term: update.term,
                            success: update.result.success,
                        },
                    });
                    showSnackbar(`Interest probed for "${update.term}".`, 'success');
                    break;

                case 'error':
                    dispatch({
                        type: 'SET_ERROR',
                        payload: { term: update.term, error: update.message },
                    });
                    showSnackbar(update.message, 'error');
                    break;

                case 'topics-found':
                    dispatch({
                        type: 'TOPICS_FOUND',
                        payload: { term: update.term, topics: update.topics },
                    });
                    break;

                case 'topic-search-complete':
                    dispatch({
                        type: 'TOPIC_SEARCH_COMPLETE',
                        payload: { term: update.term },
                    });
                    break;

                case 'topics-rated':
                    dispatch({
                        type: 'TOPICS_RATED',
                        payload: { term: update.term, topics: update.topics },
                    });
                    break;

                case 'no-topics-match':
                    dispatch({
                        type: 'NO_TOPICS_MATCH',
                        payload: { term: update.term },
                    });
                    break;

                case 'topics-rating-complete':
                    dispatch({
                        type: 'TOPICS_RATING_COMPLETE',
                        payload: { term: update.term },
                    });
                    break;

                case 'interest-probe-complete':
                    dispatch({
                        type: 'INTEREST_PROBE_COMPLETE',
                        payload: { terms: update.terms },
                    });
                    break;

                case 'interest-probe-failed':
                    dispatch({
                        type: 'INTEREST_PROBE_FAILED',
                        payload: { terms: update.terms },
                    });
                    break;

                case 'workflow-state-changed':
                    const { newState } = update;
                    if (newState === 'completed') {
                        // Update all terms to completed
                        dispatch({ type: 'MARK_ALL_TERMS_COMPLETED' });
                    }
                    break;

                default:
                    // console.warn(`Unhandled event type: ${event}`);
                    break;
                }
            } catch (error) {
                console.error('Error handling SSE message:', error);
            }
        };

        const unsubscribe = subscribe(handleMessage);

        return () => {
            unsubscribe();
        };
    }, [subscribe, showSnackbar]);

    // Add useEffect to check if processing is complete
    useEffect(() => {
        if (state.terms.length) {
            const allTermsCompleted = state.terms.every(term => term.status === WorkflowTermStatus.Complete);

            // console.log('All terms:', state.terms.map(term => `${term.term}: ${term.status}`));

            if (allTermsCompleted) {
                onProcessComplete && onProcessComplete(true);
            } else {
                onProcessComplete && onProcessComplete(false);
            }
        }
    }, [state.terms, onProcessComplete]);

    return (
        <Box>
            {/* Input Section for Adding/Search Terms */}
            <Box display="flex" alignItems="center" gap={2} mb={2}>
                <TextField
                    value={currentSearchTerm}
                    onChange={(e) => setCurrentSearchTerm(e.target.value)}
                    variant="outlined"
                    label="Search Term"
                    // Removed fullWidth and set a smaller fixed width
                    size="small" // Added size for a more compact input
                    style={{ width: '200px' }} // Set fixed width to make input smaller
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            handleAddTerm();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddTerm}
                    startIcon={<AddIcon />}
                    size="medium" // Ensured consistent size
                    style={{ height: '40px' }} // Set explicit height to match buttons
                >
                    Add
                </Button>
                <Button
                    variant="contained" // Changed from 'outlined' to 'contained'
                    color="primary" // Changed from 'secondary' to 'primary'
                    onClick={handleGenerateTerms}
                    startIcon={<AutorenewIcon />} // Added startIcon
                    disabled={isGenerating}
                    size="medium" // Ensured consistent size
                    style={{ height: '40px' }} // Set explicit height to match buttons
                >
                    Generate Terms
                </Button>
                {isGenerating && <CircularProgress size={24} />}
            </Box>

            {/* Flexbox Container for Search Terms */}
            <Box
                display="flex"
                flexWrap="wrap"
                gap={2} // Adds consistent spacing between cards
                justifyContent="flex-start"
                alignItems="flex-start" // Add this line to prevent stretch
            >
                {state.terms.map((term) => (
                    <TermCard
                        key={term.term}
                        term={term}
                        toggleTermPinnedStatus={togglePinTerm}
                        toggleTermExcludedStatus={toggleExcludeTerm}
                        handleTopicRating={handleTopicRating}
                        toggleTopicExcludedStatus={toggleTopicExcludedStatus}
                        triggerTopicRenewal={triggerTopicRenewal} // Pass the new function as prop
                    />
                ))}
            </Box>

            {/* Optional: Display Global Errors */}
            {state.error && (
                <Box mt={2}>
                    {/* Errors are handled via context, so this can be omitted or used for specific cases */}
                </Box>
            )}
        </Box>
    );
};

export default TermManager;
