// columns.ts
import { SupportedImportType } from "../supported-data-import-types";

// Step 1: Define Constants for Unique Columns

export const skuColumn = {
    name: "sku",
    type: "string",
    description: "Stock Keeping Unit unique ID",
    alternativeNames: [
        "sku",
        "sku number",
        "product code",
        "item number",
        "item code",
        "product id",
        "stock code",
        "article number",
    ],
} as const;

export const productNameColumn = {
    name: "productName",
    type: "string",
    description: "Name of the product or category",
    alternativeNames: [
        "productName",
        "product name",
        "item name",
        "product",
        "item",
        "product description",
        "product title",
        "category name",
        "category",
    ],
} as const;

export const expectedProfitMarginColumn = {
    name: "expectedProfitMargin",
    type: "number",
    description:
        "Expected profit margin for the product, category, sale or transaction",
    alternativeNames: [
        "expectedProfitMargin",
        "profit margin",
        "margin",
        "expected margin",
        "profitability",
        "profit percentage",
        "margin percentage",
        "expected profitability",
    ],
} as const;

export const itemCountColumn = {
    name: "itemCount",
    type: "number",
    description: "Total number of units sold",
    alternativeNames: [
        "itemCount",
        "quantity sold",
        "units sold",
        "sales volume",
        "quantity",
        "units",
        "sold quantity",
        "total units",
    ],
} as const;

export const salesAmountColumn = {
    name: "salesAmount",
    type: "number",
    description: "Total revenue generated from sales",
    alternativeNames: [
        "salesAmount",
        "revenue",
        "total revenue",
        "sale amount",
        "transaction amount",
        "order amount",
        "amount sold",
        "price",
        "total price",
        "amount",
        "total",
    ],
} as const;

export const dateColumn = {
    name: "date",
    type: "date",
    description: "Date of the sale",
    alternativeNames: [
        "date",
        "sale date",
        "transaction date",
        "order date",
        "date sold",
        "date of sale",
        "date of transaction",
        "purchase date",
        "sale time",
        "time sold",
        "time of sale",
        "time of transaction",
        "purchase time",
    ],
} as const;

export const salesGrowthColumn = {
    name: "salesGrowth",
    type: "number",
    description: "Sales growth percentage",
    alternativeNames: [
        "salesGrowth",
        "growth rate",
        "yoy growth",
        "sales growth",
        "revenue growth",
        "growth percentage",
        "percent growth",
        "sales increase",
        "growth",
    ],
} as const;

export const regionStoreColumn = {
    name: "regionStore",
    type: "string",
    description: "Region or store of the sale",
    alternativeNames: [
        "regionStore",
        "region",
        "store",
        "location",
        "area",
        "territory",
        "store name",
        "store id",
        "region name",
        "branch",
    ],
} as const;

// Step 2: Refactor the salesImport Object

export const salesImport: SupportedImportType = {
    value: "sales",
    label: "Sales Figures",
    description:
        "Historical sales data, revenue by product/category, and sales growth metrics.",
    analyses: [
        // **ABC Analysis**
        {
            name: "salesABCAnalysis",
            description:
                "ABC (A/B/C) Classification of Products Based on Sales Volume and Revenue",
            details:
                "ABC analysis is a method of categorizing products based on their sales volume and revenue. The analysis helps businesses identify the most important products (A), the moderately important products (B), and the least important products (C). We will use this to help you identify the products that contribute the most to your sales revenue.",
            requiresTimeSeries: true,
            requiredColumns: [skuColumn, salesAmountColumn],
            optionalColumns: [
                expectedProfitMarginColumn,
                itemCountColumn,
                productNameColumn,
            ],
        },
        // **Total Revenue Over Time**
        {
            name: "salesTotalRevenueOverTime",
            description: "Total revenue over time",
            requiresTimeSeries: true,
            requiredColumns: [dateColumn, salesAmountColumn],
        },
        // **Top Selling Products**
        {
            name: "salesTopSellingProducts",
            description: "Top-selling products/categories",
            requiresTimeSeries: false,
            requiredColumns: [productNameColumn, salesAmountColumn],
        },
        // **Sales Comparison Across Regions**
        {
            name: "salesSalesComparisonAcrossRegions",
            description: "Comparison of sales across regions/stores",
            requiresTimeSeries: false,
            requiredColumns: [regionStoreColumn, salesAmountColumn],
        },
        // **Year Over Year Growth Analysis**
        {
            name: "salesYearOverYearGrowthAnalysis",
            description: "Year-over-year growth analysis",
            requiresTimeSeries: true,
            requiredColumns: [dateColumn, salesGrowthColumn],
        },
        // **Autocorrelation Analysis**
        {
            name: "salesAutocorrelationAnalysis",
            description: "Autocorrelation Analysis for Sales",
            requiresTimeSeries: true,
            requiredColumns: [dateColumn, salesAmountColumn, productNameColumn],
        },
        // **Peak Sales Times**
        {
            name: "salesPeakSalesTimes",
            description: "Peak Sales Times",
            requiresTimeSeries: true,
            requiredColumns: [dateColumn, salesAmountColumn, productNameColumn],
        },
        // **Peak Sales Dates**
        {
            name: "salesPeakSalesDates",
            description: "Peak Sales Dates",
            requiresTimeSeries: true,
            requiredColumns: [dateColumn, salesAmountColumn, productNameColumn],
        },
        // **Sales Trend Analysis**
        {
            name: "salesSalesTrendAnalysis",
            description: "Sales Trend Analysis",
            requiresTimeSeries: true,
            requiredColumns: [dateColumn, salesAmountColumn, productNameColumn],
        },
    ],
} as const;
