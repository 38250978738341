import React, { useState, useEffect } from "react";
import {
    Box,
    Typography,
    FormControlLabel,
    Checkbox,
    Link,
    Stack,
    TextField,
    Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { UserProvider, useUser } from "./user-provider";
import BusinessSetupStepper from "./business-setup-stepper";

const BusinessIntegrations: React.FC = () => {
    return (
        <>
            <UserProvider>
                <BusinessIntegrationsInner />
            </UserProvider>
            <BusinessSetupStepper activeStep={1} />
        </>
    );
};

const BusinessIntegrationsInner: React.FC = () => {
    const { businessSetupCompletedSteps, setBusinessSetupCompletedSteps } =
        useUser();

    useEffect(() => {
        setBusinessSetupCompletedSteps(
            new Set([...businessSetupCompletedSteps, 1]),
        );
    });

    const [integrations, setIntegrations] = useState({
        googleTrends: true,
        tiktok: false,
        googleAds: false,
        stockControl: false,
        googleAnalytics: false,
        customAPI: false, // Add customAPI to state
    });

    const [customAPIConfig, setCustomAPIConfig] = useState({
        url: "",
        apiKey: "",
    });

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setIntegrations({
            ...integrations,
            [event.target.name]: event.target.checked,
        });
    };

    const handleConfigChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomAPIConfig({
            ...customAPIConfig,
            [event.target.name]: event.target.value,
        });
    };

    const handleCallAnalyse = () => {
        // Dummy function to simulate API call and data schema analysis
        console.log(
            "Calling API with URL:",
            customAPIConfig.url,
            "and API Key:",
            customAPIConfig.apiKey,
        );
        // Implement actual API call and analysis logic here
    };

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Business Integration
                </Typography>
                <Stack direction="column" spacing={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={integrations.googleTrends}
                                onChange={handleCheckboxChange}
                                name="googleTrends"
                            />
                        }
                        label="Integrate with Google Trends"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={integrations.tiktok}
                                onChange={handleCheckboxChange}
                                name="tiktok"
                            />
                        }
                        label="Integrate with TikTok"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={integrations.googleAds}
                                onChange={handleCheckboxChange}
                                name="googleAds"
                            />
                        }
                        label="Integrate with your Google Ads Console"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={integrations.stockControl}
                                onChange={handleCheckboxChange}
                                name="stockControl"
                            />
                        }
                        label="Integrate with your stock control system"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={integrations.googleAnalytics}
                                onChange={handleCheckboxChange}
                                name="googleAnalytics"
                            />
                        }
                        label="Integrate with your Google Analytics"
                    />
                    {/* New Custom API Integration Checkbox */}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={integrations.customAPI}
                                onChange={handleCheckboxChange}
                                name="customAPI"
                            />
                        }
                        label="Custom API integration"
                    />
                </Stack>
                {/* Conditional rendering for Custom API configuration */}
                {integrations.customAPI && (
                    <Box sx={{ mt: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Custom API Configuration
                        </Typography>
                        <TextField
                            label="API URL"
                            name="url"
                            value={customAPIConfig.url}
                            onChange={handleConfigChange}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="API Key"
                            name="apiKey"
                            value={customAPIConfig.apiKey}
                            onChange={handleConfigChange}
                            fullWidth
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCallAnalyse}
                            sx={{ mt: 1 }}
                        >
                            Call and Analyse Data Schema
                        </Button>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default BusinessIntegrations;
