import React from 'react';
import {
    Stepper,
    Step,
    StepLabel,
    Typography,
    useMediaQuery,
    useTheme,
    Box,
    MobileStepper,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InsightsIcon from '@mui/icons-material/Insights';
import useBreakpoint from './use-breakpoint';

interface ResponsiveStepperProps {
    activeStep: number;
    expandedSections: boolean[];
    onToggleStep: (step: number, isExpanded: boolean) => void;
}

// Step data (labels and icons encapsulated)
const steps = [
    {
        label: 'Trend search',
        description: 'Enter information about your trend search',
        icon: <SearchIcon />,
    },
    {
        label: 'Select from search and keyword interest sources',
        description: 'Choose sources for trend analysis',
        icon: <DataUsageIcon />,
    },
    {
        label: 'Enter specific search terms',
        description: 'Input search terms for analysis',
        icon: <TextFieldsIcon />,
    },
    {
        label: 'Visual overview',
        description: 'View time series charts',
        icon: <ShowChartIcon />,
    },
    {
        label: 'Analysis and recommendations',
        description: 'Get detailed insights and suggestions',
        icon: <InsightsIcon />,
    },
];

const ResponsiveStepper: React.FC<ResponsiveStepperProps> = ({ activeStep, expandedSections, onToggleStep }) => {
    // const theme = useTheme();

    const breakpoints = useBreakpoint();

    const handleStepClick = (index: number) => {
        const newState = !expandedSections[index];
        onToggleStep(index, newState);
    };

    switch (breakpoints.breakpoint) {
    case 'xl':
        return (
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => handleStepClick(index)}>
                        <StepLabel>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h6">{step.label}</Typography>
                                <Typography variant="body1">{step.description}</Typography>
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );

    case 'lg':
        return (
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => handleStepClick(index)}>
                        <StepLabel>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h6">{step.label}</Typography>
                                <Typography variant="body2">{step.description}</Typography>
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );

    case 'md':
        return (
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => handleStepClick(index)}>
                        <StepLabel>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="body2">{step.label}</Typography>
                            </Box>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        );
    case 'sm':
    case 'xs':
        return (
            <MobileStepper variant="dots" steps={steps.length} position="static" activeStep={activeStep} backButton={''} nextButton={''} />
        );
    }
};

export default ResponsiveStepper;
