import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useBreakpoint = () => {
  const theme = useTheme();

  const xl = useMediaQuery(theme.breakpoints.up('xl')); // 1536px and up
  const lg = useMediaQuery(theme.breakpoints.up('lg')); // 1200px and up
  const md = useMediaQuery(theme.breakpoints.up('md')); // 900px and up
  const sm = useMediaQuery(theme.breakpoints.up('sm')); // 600px and up
  const xs = !sm; // Below 600px (when sm is not active)

  let breakpoint = 'xs'; // Default to 'xs'

  if (xl) {
    breakpoint = 'xl';
  } else if (lg) {
    breakpoint = 'lg';
  } else if (md) {
    breakpoint = 'md';
  } else if (sm) {
    breakpoint = 'sm';
  }

  return {
    xl,
    lg,
    md,
    sm,
    xs,
    breakpoint, // 'xs', 'sm', 'md', 'lg', 'xl'
  };
};

export default useBreakpoint;
