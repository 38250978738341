import { SupportedImportType } from "../supported-data-import-types";

export const advertisingImport: SupportedImportType = {
    value: "advertising",
    label: "Advertising Spend and ROI",
    description: "Budgets allocated to different advertising channels and the return on investment (ROI) metrics for each effort.",
    analyses: [
        {
            name: "adsRoiComparisonAcrossChannels",
            description: "ROI comparison across channels",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "campaignName",
                    description: "Name of the campaign",
                    alternativeNames: [
                        "campaign",
                        "ad campaign",
                        "campaign title",
                        "marketing campaign",
                    ],
                },
                {
                    name: "channel",
                    description: "Advertising channel",
                    alternativeNames: [
                        "ad channel",
                        "platform",
                        "medium",
                        "advertising channel",
                    ],
                },
                {
                    name: "roi",
                    description: "Return on investment",
                    alternativeNames: [
                        "ROI",
                        "return on investment",
                        "investment return",
                        "profitability",
                        "roi percentage",
                    ],
                },
            ],
        },
        {
            name: "adsCostPerAcquisition",
            description: "Cost per acquisition (CPA)",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "campaignName",
                    description: "Name of the campaign",
                    alternativeNames: [
                        "campaign",
                        "ad campaign",
                        "campaign title",
                        "marketing campaign",
                    ],
                },
                {
                    name: "channel",
                    description: "Advertising channel",
                    alternativeNames: [
                        "ad channel",
                        "platform",
                        "medium",
                        "advertising channel",
                    ],
                },
                {
                    name: "spendAmount",
                    description: "Amount spent on the campaign",
                    alternativeNames: [
                        "ad spend",
                        "budget",
                        "spending",
                        "cost",
                        "amount spent",
                        "campaign cost",
                    ],
                },
            ],
        },
        {
            name: "adsTrendAnalysisAdSpendVsRevenue",
            description: "Trend analysis of ad spend vs. revenue",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "campaignName",
                    description: "Name of the campaign",
                    alternativeNames: [
                        "campaign",
                        "ad campaign",
                        "campaign title",
                        "marketing campaign",
                    ],
                },
                {
                    name: "spendAmount",
                    description: "Amount spent on the campaign",
                    alternativeNames: [
                        "ad spend",
                        "budget",
                        "spending",
                        "cost",
                        "amount spent",
                        "campaign cost",
                    ],
                },
                {
                    name: "revenueGenerated",
                    description: "Revenue generated from the campaign",
                    alternativeNames: [
                        "revenue",
                        "sales",
                        "income",
                        "earnings",
                        "campaign revenue",
                    ],
                },
            ],
        },
    ],
};
