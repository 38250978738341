import React, { useState, useEffect, useRef } from 'react';

import {
    Box,
    TextField,
    IconButton,
    Typography,
    useMediaQuery,
    Theme,
    CircularProgress,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import HistoryIcon from '@mui/icons-material/ManageHistory';
import DeleteIcon from '@mui/icons-material/Delete';

import ReactMarkdown from 'react-markdown'; // Import react-markdown

import { keyframes } from '@mui/system'; // Import keyframes for animations
import { getAPIClient } from './client-id';
import axios from 'axios';

interface ChatbotProps {
    persona: string;
    userMemoriesName: string;
    chatMemoriesName?: string;
    chatMemoriesId?: string | null;
    onFinished?: (isFinished: boolean) => void;
    chatIsFinished?: boolean;
    chatId?: string | null;
    onChatStarted?: (chatId: string) => void; // Make onChatStarted optional
}

// Define keyframes for the typing animation
const bounce = keyframes`
  0% { transform: scale(1); opacity: 0.3; }
  50% { transform: scale(1.3); opacity: 1; }
  100% { transform: scale(1); opacity: 0.3; }
`;

// TypingIndicator Component
const TypingIndicator: React.FC = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Box
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                animation: `${bounce} 1s infinite`,
            }}
        />
        <Box
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                animation: `${bounce} 1s infinite`,
                animationDelay: '0.2s',
            }}
        />
        <Box
            sx={{
                width: 8,
                height: 8,
                borderRadius: '50%',
                bgcolor: 'primary.contrastText',
                animation: `${bounce} 1s infinite`,
                animationDelay: '0.4s',
            }}
        />
    </Box>
);

const Chatbot: React.FC<ChatbotProps> = ({
    persona,
    userMemoriesName,
    chatMemoriesName,
    chatMemoriesId,
    chatIsFinished,
    chatId: initialChatId,
    onFinished,
    onChatStarted,
}) => {
    const [messages, setMessages] = useState<{ user: string; text: string }[]>([]);
    const [message, setMessage] = useState('');
    const [userMemories, setUserMemories] = useState<{ [id: string]: string }>({});
    const [chatMemories, setChatMemories] = useState<{ [id: string]: string }>({});
    const [showMemories, setShowMemories] = useState(false); // State to toggle between chat and memories
    const [loading, setLoading] = useState(false); // Loading state
    const [isFinished, setIsFinished] = useState<boolean>(chatIsFinished ?? false);
    const [chatId, setChatId] = useState<string | null>(initialChatId ?? null);

    // Responsive breakpoint
    // const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    // Ref to the end of the messages list
    const messagesEndRef = useRef<HTMLDivElement>(null);

    // Scroll to the bottom whenever messages or loading state change
    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, loading]);

    // Start a new chat or load existing chat history
    useEffect(() => {
        console.log(`chatbot useEffect chatId: ${chatId}...`);

        if (chatId == null) {
            console.log('chatId is null, starting new chat...');

            // Start a new chat
            const startNewChat = async () => {
                setLoading(true);
                try {
                    const response = await getAPIClient().post<{chat_id: string, message: string, is_finished: boolean}>('/api/v1/chat', {
                        persona,
                        memories_id: chatMemoriesId
                    });
                    if (response.data.chat_id && onChatStarted) {
                        onChatStarted(response.data.chat_id);
                        setChatId(response.data.chat_id);
                    }
                    // Add assistant's opening message
                    setMessages([{ user: 'Bot', text: response.data.message }]);
                    setIsFinished(response.data.is_finished);

                    // Fetch memories after starting a new chat
                    await fetchMemories();

                } catch (error) {
                    console.error('Error starting new chat:', error);
                } finally {
                    setLoading(false);
                }
            };

            startNewChat();
        } else if (!messages.length) {
            console.log('chatId is not null, loading existing chat history...');

            // Load existing chat history
            const fetchChatHistory = async () => {
                setLoading(true);
                try {
                    const response = await getAPIClient().get(`/api/v1/chat/${chatId}`);
                    const fetchedMessages = response.data.messages
                        .filter((msg: any) => msg.role !== 'system') // Exclude system messages
                        .map((msg: any) => ({
                            user: msg.role === 'user' ? 'You' : 'Bot',
                            text: msg.content,
                        }));

                    console.log(`existing chat history ${chatId}`, JSON.stringify(fetchedMessages, null, 2));

                    await fetchMemories();

                    setMessages(fetchedMessages);
                    setIsFinished(response.data.isFinished);
                    if (response.data.isFinished && onFinished) {
                        onFinished(true);
                    }

                    // Fetch memories after fetching chat history

                } catch (error) {
                    if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
                        // this can happen if the user has deleted the chat but the chat ID
                        // is still stored somewhere (e.g. business info chat ID)
                        console.log(`chat history not found for chatId ${chatId}, starting new chat...`);
                        setChatId(null);
                    }   

                    console.error('Error fetching chat history:', error);
                } finally {
                    setLoading(false);
                }
            };

            fetchChatHistory();
        }
    }, [chatId]);

    // Fetch memories
    const fetchMemories = async () => {
        try {
            const userMemoriesResponse = await getAPIClient().get(`/api/v1/memories/user`);

            console.log('userMemoriesResponse', userMemoriesResponse.data.memories);

            setUserMemories(userMemoriesResponse.data.memories);

            if (chatMemoriesName && chatMemoriesId) {
                const chatMemoriesResponse = await getAPIClient().get(`/api/v1/memories/${chatMemoriesId}`);

                console.log(`chatMemoriesResponse ${chatMemoriesId}`, chatMemoriesResponse.data.memories);

                setChatMemories(chatMemoriesResponse.data.memories);
            }
        } catch (error) {
            console.error('Error fetching memories:', error);
        }
    };

    const handleSend = async () => {
        if (message.trim() && chatId) {
            // If currently showing memories, toggle back to chat
            if (showMemories) {
                setShowMemories(false);
            }

            // Add user message
            setMessages((prevMessages) => [...prevMessages, { user: 'You', text: message }]);
            setMessage('');
            setLoading(true); // Set loading to true when sending a message

            // After user sends a message, set isFinished to false
            setIsFinished(false);
            if (onFinished) {
                onFinished(false);
            }

            try {
                // Send the message to the chat API
                const response = await getAPIClient().post(`/api/v1/chat/${chatId}/message`, {
                    message,
                });

                // Add bot response
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { user: 'Bot', text: response.data.message },
                ]);
                setLoading(false); // Set loading to false after receiving the response

                if (response.data.is_finished) {
                    setIsFinished(true);
                    if (onFinished) {
                        onFinished(true);
                    }
                }

                // Fetch memories after bot response
                await fetchMemories();
            } catch (error) {
                console.error('Error sending message:', error);
                setLoading(false); // Ensure loading is false even if there's an error
            }
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    };

    const handleToggleMemories = () => {
        setShowMemories((prev) => !prev);
    };

    const handleDeleteUserMemory = async (id: string) => {
        try {
            await getAPIClient().delete(`/api/v1/memories/user/${id}`);
            setUserMemories((prevMemories) => {
                const updatedMemories = { ...prevMemories };
                delete updatedMemories[id];
                return updatedMemories;
            });
        } catch (error) {
            console.error('Error deleting user memory:', error);
        }
    };

    const handleDeleteChatMemory = async (id: string) => {
        try {
            if (chatMemoriesId) {
                await getAPIClient().delete(`/api/v1/memories/${chatMemoriesId}/${id}`);
                setChatMemories((prevMemories) => {
                    const updatedMemories = { ...prevMemories };
                    delete updatedMemories[id];
                    return updatedMemories;
                });
            }
        } catch (error) {
            console.error('Error deleting chat memory:', error);
        }
    };

    const deleteChat = async () => {
        if (chatId) {
            try {
                setChatId(null);
                setMessages([]);
                setIsFinished(false);

                await getAPIClient().delete(`/api/v1/chat/${chatId}`);
            } catch (error) {
                console.error('Error deleting chat:', error);
            }
        }
    };

    // Placeholder when chat is not yet created
    if (!messages.length && !loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
            }}
        >
            {/* Main Content Area */}
            <Box
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
            >
                {/* Chat Messages or Memories List */}
                <Box
                    sx={{
                        flexGrow: 1,
                        overflowY: 'auto',
                        p: 0,
                        m: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        backgroundColor: 'background.default',
                    }}
                >
                    {!showMemories ? (
                        <>
                            {messages.map((msg, index) => (
                                <Box
                                    key={index}
                                    data-testid={msg.user === 'You' ? 'user-message' : 'bot-message'}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: msg.user === 'You' ? 'flex-end' : 'flex-start',
                                        mb: 1,
                                        px: 0, // Ensure no horizontal external padding
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 1.5, // Consistent internal padding
                                            backgroundColor: msg.user === 'You' ? 'primary.main' : 'secondary.main',
                                            color: 'primary.contrastText',
                                            borderRadius: 2,
                                            maxWidth: {
                                                xs: '95%', // 0px and up
                                                sm: '85%', // 600px and up
                                                md: '70%', // 900px and up
                                            },
                                        }}
                                    >
                                        <ReactMarkdown>{msg.text}</ReactMarkdown>
                                    </Box>
                                </Box>
                            ))}

                            {/* Typing Indicator */}
                            {loading && (
                                <Box
                                    data-testid="typing-indicator"
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        mb: 1,
                                        px: 0, // Set to 0 to match message external padding
                                    }}
                                >
                                    <Box
                                        sx={{
                                            p: 1.5,
                                            backgroundColor: 'secondary.main',
                                            color: 'primary.contrastText',
                                            borderRadius: 2,
                                            maxWidth: '70%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <TypingIndicator />
                                    </Box>
                                </Box>
                            )}

                            {/* Ref to scroll to the bottom */}
                            <div ref={messagesEndRef} />
                        </>
                    ) : (
                        // Memories List
                        <Box sx={{ overflowY: 'auto', maxHeight: '100%', px: 1, py: 2 }}>
                            {/* User Memories Section */}
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h6">{userMemoriesName}</Typography>
                                {userMemories && Object.keys(userMemories).length > 0 ? (
                                    <ul style={{ paddingLeft: '20px' }}>
                                        {Object.entries(userMemories).map(([id, memory]) => (
                                            <li key={id}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                                                        {memory}
                                                    </Typography>
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleDeleteUserMemory(id)}
                                                        size="small"
                                                        aria-label="Delete Memory"
                                                    >
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Typography variant="body1">
                                        No {userMemoriesName} yet.
                                    </Typography>
                                )}
                            </Box>

                            {/* Chat Memories Section */}
                            {chatMemoriesName && (
                                <Box>
                                    <Typography variant="h6">{chatMemoriesName}</Typography>
                                    {chatMemories && Object.keys(chatMemories).length > 0 ? (
                                        <ul style={{ paddingLeft: '20px' }}>
                                            {Object.entries(chatMemories).map(([id, memory]) => (
                                                <li key={id}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                        <Typography
                                                            variant="body1"
                                                            sx={{ flexGrow: 1 }}
                                                        >
                                                            {memory}
                                                        </Typography>
                                                        <IconButton
                                                            color="secondary"
                                                            onClick={() =>
                                                                handleDeleteChatMemory(id)
                                                            }
                                                            size="small"
                                                            aria-label="Delete Memory"
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    </Box>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <Typography variant="body1">
                                            No {chatMemoriesName} yet.
                                        </Typography>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', p: 0, borderTop: '1px solid #ddd' }}>
                    <IconButton
                        color="primary"
                        onClick={handleToggleMemories}
                        sx={{ mr: isFinished ? 0 : 1 }} // Adjust margin if input is hidden
                        aria-label="Toggle Memories"
                    >
                        <HistoryIcon />
                    </IconButton>

                    <IconButton
                        color="primary"
                        onClick={deleteChat}
                        sx={{ mr: 1 }} // Adjust margin if input is hidden
                        aria-label="Delete Chat"
                    >
                        <DeleteIcon />
                    </IconButton>

                    {!isFinished && (
                        <>
                            <TextField
                                fullWidth
                                multiline
                                minRows={1}
                                maxRows={4}
                                placeholder="Type your message"
                                variant="outlined"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                sx={{ mr: 1, flexGrow: 1 }}
                                inputProps={{ 'data-testid': 'chatbot-input' }}
                            />
                            <IconButton
                                color="primary"
                                onClick={handleSend}
                                aria-label="Send Message"
                            >
                                <SendIcon />
                            </IconButton>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );

};

export default React.memo(Chatbot);
