import * as React from 'react';
import { Typography, Box, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { InterestOverTimeStatistics, MudlarkInterestOverTimeData } from '@mudlark/common';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; // new import

const isTopic = (keyword: string) => keyword.startsWith('/g/') || keyword.startsWith('/m/');

const renderKeywordWithDescription = (keyword: string, descriptions: { [key: string]: string }) => {
  const description = descriptions[keyword] ?? keyword;

  if (isTopic(keyword)) {
    return `${description} (topic)`;
  } else {
    return `${description} (term)`;
  }
};

const renderHolidays = (holidays: { date: string; name: string }[]) => (
  <Box mt={4}>
    <Typography variant="subtitle1">Holidays:</Typography>
    <List>
      {holidays.map((holiday, index) => (
        <ListItem key={index}>
          <ListItemText
            primary={holiday.name}
            secondary={new Date(holiday.date).toLocaleDateString()}
          />
        </ListItem>
      ))}
    </List>
  </Box>
);

const renderStatistics = (statistics: InterestOverTimeStatistics, descriptions: { [key: string]: string }) => (
  <Box>
    <Typography variant="subtitle1">Statistics:</Typography>

    <Box mt={2}>
      <Typography variant="subtitle1">Regression:</Typography>
      <List>
        {statistics.regression.map((reg) => (
          <ListItem key={reg.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(reg.keyword, descriptions)}
              secondary={`m: ${reg.m}, b: ${reg.b}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Ranking:</Typography>
      <List>
        {statistics.ranking.map((rank) => (
          <ListItem key={rank.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(rank.keyword, descriptions)}
              secondary={`mean: ${rank.mean}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Outliers:</Typography>
      <List>
        {statistics.outliers.map((outlier) => (
          <ListItem key={outlier.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(outlier.keyword, descriptions)}
              secondary={`Outliers: ${outlier.outliers.map(o => `${new Date(o.date)}: ${o.value}`).join(', ')}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Volatility:</Typography>
      <List>
        {statistics.volatility.map((vol) => (
          <ListItem key={vol.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(vol.keyword, descriptions)}
              secondary={`Volatility: ${vol.volatility}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Peak:</Typography>
      <List>
        {statistics.peak.map((peak) => (
          <ListItem key={peak.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(peak.keyword, descriptions)}
              secondary={`Peak: ${peak.peak}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Z-Score Initial:</Typography>
      <List>
        {statistics.zScoreInitial.map((zScore) => (
          <ListItem key={zScore.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(zScore.keyword, descriptions)}
              secondary={`Z-Score: ${zScore.zScore}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Positive Slope:</Typography>
      <List>
        {statistics.positiveSlope.map((slope) => (
          <ListItem key={slope.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(slope.keyword, descriptions)}
              secondary={`Slope: ${slope.slope}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Normalized Growth Rate:</Typography>
      <List>
        {statistics.normalizedGrowthRate.map((growthRate) => (
          <ListItem key={growthRate.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(growthRate.keyword, descriptions)}
              secondary={`Growth Rate: ${growthRate.growthRate}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>

    <Box mt={2}>
      <Typography variant="subtitle1">Autocorrelations:</Typography>
      <List>
        {statistics.autocorrelation.map((autoCorr) => (
          <ListItem key={autoCorr.keyword}>
            <ListItemText
              primary={renderKeywordWithDescription(autoCorr.keyword, descriptions)}
              secondary={autoCorr.autocorrelations
                .map(ac => `Lag ${ac.lag} days: ${ac.autocorrelation.toFixed(4)}`)
                .join(', ')}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  </Box>
);

type StatisticsSectionProps = {
  // recommendations: string | null;
  // sortedKeywords: string[];
  results: MudlarkInterestOverTimeData | null;
};

// Modify WorkflowResults to only render statistics within an Accordion
const StatisticsSection: React.FC<StatisticsSectionProps> = ({
  results, // Ensure results are passed as props
}) => {
  return (
    <Box data-testid="analysis-section">
      {results ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">Statistics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderStatistics(results.statistics, {})}
          </AccordionDetails>
        </Accordion>
      ) : (
        <Typography>No detailed statistics available.</Typography>
      )}
    </Box>
  );
};

export default StatisticsSection;
