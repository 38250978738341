import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Typography } from '@mui/material';

type RecommendationsSectionProps = {
    recommendations: string | null;
};

const RecommendationsSection: React.FC<RecommendationsSectionProps> = ({ recommendations }) => {
    return (
        <Box mt={4}>
            <Typography variant="h5">Recommendations</Typography>
            {recommendations ? (
                <ReactMarkdown>{recommendations}</ReactMarkdown>
            ) : (
                <Typography>No recommendations available.</Typography>
            )}
        </Box>
    );
};

export default RecommendationsSection;
