// TermCard.tsx

import React, { memo, useState, useMemo, useCallback } from 'react';
import { styled, keyframes } from '@mui/material/styles';
import {
    Card,
    CardContent,
    Typography,
    Rating,
    Box,
    Tooltip,
    IconButton,
    Collapse,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import Cancel from '@mui/icons-material/Cancel';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import LiveIcon from '@mui/icons-material/SentimentVerySatisfied'; // Correct icon for "very satisfied"
import DeadIcon from '@mui/icons-material/SentimentVeryDissatisfied'; // Correct icon for "very dissatisfied"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RefreshIcon from '@mui/icons-material/Refresh'; // Import Refresh icon

import { useSnackbar } from './snackbar-context'; // Import the custom hook
import { WorkflowTermSelectionStatus } from './term'; // Import the WorkflowTerm and WorkflowTopic types
import { WorkflowTerm, WorkflowTopic } from '@mudlark/common';

// Define the interface for the TermCard props, including toggle functions and the new auto-renew function
interface TermCardProps {
    term: WorkflowTerm;
    toggleTermPinnedStatus: (term: string) => void;
    toggleTermExcludedStatus: (term: string) => void;
    handleTopicRating: (term: string, mid: string, relevance_score: number) => void;
    toggleTopicExcludedStatus: (term: string, mid: string) => void;
    triggerTopicRenewal: (term: string) => Promise<void>; // New prop for auto-renew
}

// Styled components for consistent styling
const StyledCard = styled(Card)(({ theme }) => ({
    width: '300px', // Fixed width
    backgroundColor: '#fff',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    flexShrink: 0, // Prevent the card from shrinking
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(2),
}));

const TitleContainer = styled('div')<{ hasUsableTopics: boolean }>(({ hasUsableTopics }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    backgroundColor: hasUsableTopics ? 'rgba(0, 255, 0, 0.1)' : 'inherit',
}));

const TitleText = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1,
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
});

const BestTopicText = styled(Typography)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    fontSize: '0.9rem',
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
}));

const IconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
});

const ContentContainer = styled('div')({
    marginTop: '8px',
});

const TopicsContainer = styled('div')({
    maxHeight: '200px',
    overflowY: 'auto',
});

const GreyedOut = styled('div')({
    color: 'grey',
    opacity: 0.6,
});

// Define keyframes for a simple ellipsis animation
const ellipsis1 = keyframes`
  0% { content: '.'; }
  33% { content: '..'; }
  66% { content: '...'; }
  100% { content: '.'; }
`;

const Ellipsis = styled('span')(({ theme }) => ({
    '&::after': {
        content: "'.'",
        animation: `${ellipsis1} 1.5s infinite`,
    },
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

// Helper function to determine the best topic
const getBestTopic = (topics: WorkflowTopic[]): WorkflowTopic | null => {
    // Filter live topics
    const liveTopics = topics.filter((topic) => topic.live === true);
    if (liveTopics.length > 0) {
        // Return the live topic with highest relevance_score
        return liveTopics.reduce((prev, current) =>
            (prev.relevance_score ?? 0) > (current.relevance_score ?? 0) ? prev : current
        );
    }

    // Filter rated topics
    const ratedTopics = topics.filter((topic) => topic.relevance_score != null);
    if (ratedTopics.length > 0) {
        // Return the highest rated topic
        return ratedTopics.reduce((prev, current) =>
            (prev.relevance_score ?? 0) > (current.relevance_score ?? 0) ? prev : current
        );
    }

    // Check if any topics have their live status determined
    const topicsWithLiveStatus = topics.filter((topic) => topic.live !== undefined);
    if (topicsWithLiveStatus.length > 0) {
        // All topics are dead if none are live
        const hasLive = topics.some((topic) => topic.live === true);
        if (!hasLive) {
            return null;
        }
    }

    // If no topics have live status, pick the topic with highest relevance_score
    if (topics.length > 0) {
        return topics.reduce((prev, current) =>
            (prev.relevance_score ?? 0) > (current.relevance_score ?? 0) ? prev : current
        );
    }

    return null;
};

const TermCard: React.FC<TermCardProps> = memo(
    ({
        term,
        toggleTermPinnedStatus,
        toggleTermExcludedStatus,
        handleTopicRating,
        toggleTopicExcludedStatus,
        triggerTopicRenewal, // Destructure the new prop
    }) => {
        const { showSnackbar } = useSnackbar(); // Access the Snackbar context

        const [isExpanded, setIsExpanded] = useState(false);

        const handleToggleExpand = () => {
            setIsExpanded((prev) => !prev);
        };

        const topicsArray = useMemo(() => Object.values(term.topics), [term.topics]);

        const visibleTopics = useMemo(
            () => topicsArray.filter((topic) => !topic.isExcluded),
            [topicsArray]
        );

        const bestTopic = useMemo(() => getBestTopic(visibleTopics), [visibleTopics]);

        // Determine if there are live or rated topics
        const hasLive = useMemo(
            () => visibleTopics.some((topic) => topic.live === true),
            [visibleTopics]
        );
        const hasRated = useMemo(
            () => visibleTopics.some((topic) => topic.relevance_score != null),
            [visibleTopics]
        );

        // Sort the topics according to the specified groups and criteria
        const sortedTopics = useMemo(() => {
            let sorted = [...visibleTopics];

            // If any topics are live, sort live first
            if (hasLive) {
                sorted.sort((a, b) => {
                    if (a.live === true && b.live !== true) return -1;
                    if (b.live === true && a.live !== true) return 1;
                    return 0;
                });
            }

            // If any topics are rated, sort rated first
            if (hasRated) {
                sorted.sort((a, b) => {
                    if (a.relevance_score != null && b.relevance_score == null) return -1;
                    if (b.relevance_score != null && a.relevance_score == null) return 1;
                    return 0;
                });
            }

            // Finally, sort by descending relevance_score if available
            sorted.sort((a, b) => {
                if (a.relevance_score != null && b.relevance_score != null) {
                    return (b.relevance_score ?? 0) - (a.relevance_score ?? 0);
                }
                return 0;
            });

            return sorted;
        }, [visibleTopics, hasLive, hasRated]);

        // Handler for Auto Renew button click
        const handleAutoRenew = async () => {
            try {
                await triggerTopicRenewal(term.term); // Assuming 'term.term' uniquely identifies the term
                showSnackbar('Topics regeneration triggered successfully.', 'success');
            } catch (error) {
                console.error('Auto Renew failed:', error);
                showSnackbar('Failed to regenerate topics.', 'error');
            }
        };

        const termLowercase = term.term.replace(/\s+/g, '-').toLowerCase()

        return (
            <StyledCard
                data-testid="term-card"
                data-term-name={termLowercase}
                aria-labelledby={`term-title-${termLowercase}`}
                aria-label={term.hasUsableTopics ? 'Has usable topics' : 'No usable topics'}
            >
                <CardContentStyled>
                    <TitleContainer
                        hasUsableTopics={term.hasUsableTopics}
                        data-testid={`term-title-container-${termLowercase}`}
                        aria-label={term.hasUsableTopics ? 'Has usable topics' : 'No usable topics'}
                    >
                        <TitleText
                            variant="h6"
                            title={term.term}
                            id={`term-title-${termLowercase}`}
                        >
                            {term.term}
                            {!isExpanded && bestTopic && (
                                <BestTopicText variant="body2" title={bestTopic.name}>
                                    - {bestTopic.name}
                                </BestTopicText>
                            )}
                        </TitleText>
                        <IconContainer>
                            {term.selectionStatus !== WorkflowTermSelectionStatus.UserAdded && (
                                <>
                                    <IconButton
                                        onClick={() => toggleTermPinnedStatus(term.term)}
                                        aria-label={
                                            term.selectionStatus === WorkflowTermSelectionStatus.Pinned
                                                ? 'Unpin Term'
                                                : 'Pin Term'
                                        }
                                        size="small"
                                    >
                                        {term.selectionStatus === WorkflowTermSelectionStatus.Pinned ? (
                                            <PushPinIcon color="secondary" />
                                        ) : (
                                            <PushPinOutlinedIcon />
                                        )}
                                    </IconButton>
                                    <IconButton
                                        onClick={() => toggleTermExcludedStatus(term.term)}
                                        aria-label={
                                            term.selectionStatus === WorkflowTermSelectionStatus.Excluded
                                                ? 'Include Term'
                                                : 'Exclude Term'
                                        }
                                        size="small"
                                    >
                                        {term.selectionStatus === WorkflowTermSelectionStatus.Excluded ? (
                                            <Cancel color="error" />
                                        ) : (
                                            <CancelOutlined />
                                        )}
                                    </IconButton>
                                </>
                            )}
                            <Tooltip title="Auto Renew Topics">
                                <IconButton onClick={handleAutoRenew} aria-label="auto renew">
                                    <RefreshIcon />
                                </IconButton>
                            </Tooltip>
                            <ExpandButton
                                onClick={handleToggleExpand}
                                aria-label={isExpanded ? 'Collapse' : 'Expand'}
                                aria-expanded={isExpanded}
                                size="small"
                            >
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </ExpandButton>
                        </IconContainer>
                    </TitleContainer>


                    {/* <Box mt={1} mb={1}>
                        <Typography variant="body2">
                            {term.live ? (
                                <>Volume: {term.volume || '??'}, Trend: {term.trendDirection || '??'}</>
                            ) : (
                                'No interest'
                            )}
                        </Typography>
                    </Box> */}

                    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <ContentContainer data-testid={`term-topics-${term.term.replace(/\s+/g, '-').toLowerCase()}`}>
                            {sortedTopics.length > 0 ? (
                                <TopicsContainer>
                                    {sortedTopics.map((topic, index) => {
                                        // Determine if the topic should be greyed out
                                        let greyedOut = false;
                                        if (hasLive && topic.live !== true) {
                                            greyedOut = true;
                                        }
                                        if (hasRated && topic.relevance_score == null) {
                                            greyedOut = true;
                                        }

                                        // Apply greyed-out styling if necessary
                                        const TopicWrapper = greyedOut ? GreyedOut : React.Fragment;

                                        return (
                                            <TopicWrapper key={`${topic.mid}-${index}`}>
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                    mb={0.5}
                                                    data-testid={`topic-${topic.mid}`}
                                                    data-live-status={topic.live}
                                                    data-relevance-score={topic.relevance_score}
                                                    aria-label={`Topic ${topic.name}`}
                                                >
                                                    <Tooltip
                                                        title={
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                {topic.image && topic.image.contentUrl && (
                                                                    <img
                                                                        src={topic.image.contentUrl}
                                                                        alt={topic.name}
                                                                        style={{
                                                                            maxWidth: '100px',
                                                                            maxHeight: '100px',
                                                                            width: 'auto',
                                                                            height: 'auto',
                                                                            objectFit: 'contain',
                                                                            marginBottom: '8px',
                                                                        }}
                                                                        onError={(e) => { e.currentTarget.style.display = 'none'; }}
                                                                    />
                                                                )}
                                                                <Typography variant="body2">{topic.description ?? ''}</Typography>
                                                                <Typography variant="caption">{topic.mid}</Typography>
                                                            </div>
                                                        }
                                                    >
                                                        <Typography
                                                            variant="body2"
                                                            style={{
                                                                marginRight: '8px',
                                                                flexGrow: 1,
                                                                cursor: 'default',
                                                            }}
                                                        >
                                                            {topic.name}                                                            
                                                        </Typography>
                                                    </Tooltip>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() =>
                                                            toggleTopicExcludedStatus(
                                                                term.term,
                                                                topic.mid
                                                            )
                                                        }
                                                    >
                                                        {topic.isExcluded ? (
                                                            <Cancel color="error" fontSize="small" />
                                                        ) : (
                                                            <CancelOutlined fontSize="small" />
                                                        )}
                                                    </IconButton>
                                                    <Rating
                                                        value={
                                                            (topic.relevance_score ?? 0) / 2
                                                        }
                                                        precision={0.5}
                                                        size="small"
                                                        style={{ marginRight: '8px' }}
                                                        readOnly
                                                        data-testid={`topic-rating-${topic.mid}`}
                                                        aria-label={`Rating: ${topic.relevance_score ?? 'No rating'}`}
                                                    />
                                                    {topic.live === true && (
                                                        <LiveIcon
                                                            style={{
                                                                marginLeft: '8px',
                                                                color: 'green',
                                                            }}
                                                            data-testid={`topic-live-status-${topic.mid}`}
                                                            aria-label="Live topic"
                                                        />
                                                    )}
                                                    {topic.live === false && (
                                                        <DeadIcon
                                                            style={{
                                                                marginLeft: '8px',
                                                                color: 'red',
                                                            }}
                                                            data-testid={`topic-live-status-${topic.mid}`}
                                                            aria-label="Dead topic"
                                                        />
                                                    )}
                                                </Box>
                                            </TopicWrapper>
                                        );
                                    })}
                                </TopicsContainer>
                            ) : (
                                <Typography variant="body2" color="textSecondary">
                                    No topics available.
                                </Typography>
                            )}
                        </ContentContainer>
                    </Collapse>
                </CardContentStyled>
            </StyledCard>
        );
    }

);

export default TermCard;
