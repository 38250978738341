import * as React from 'react'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { strictModeWanted } from './strict-mode-config'

/*
if (strictModeWanted) {
  createRoot(document.getElementById('root')!).render(
    <StrictMode>
      <App />
    </StrictMode>
  )
} else {
  createRoot(document.getElementById('root')!).render(<App />)
}
*/

createRoot(document.getElementById('root')!).render(<App />)
