import { SupportedImportType } from "../supported-data-import-types";

export const analyticsImport: SupportedImportType = {
    value: "analytics",
    label: "Web Analytics",
    description: "Key metrics such as page views, bounce rates, conversion rates, session durations, and traffic sources.",
    analyses: [
        {
            description: "Traffic source breakdown",
            name: "webTrafficSourceBreakdown",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the metric",
                    alternativeNames: [
                        "metric date",
                        "date recorded",
                        "recorded date",
                        "event date",
                    ],
                },
                {
                    name: "trafficSource",
                    description: "Source of the traffic",
                    alternativeNames: [
                        "source",
                        "traffic source",
                        "referrer",
                        "medium",
                        "channel",
                        "referral source",
                        "acquisition source",
                    ],
                },
            ],
        },
        {
            description: "Engagement metrics (average session duration)",
            name: "webEngagementMetricsAverageSessionDuration",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the metric",
                    alternativeNames: [
                        "metric date",
                        "date recorded",
                        "recorded date",
                        "event date",
                    ],
                },
                {
                    name: "sessionDuration",
                    description: "Duration of the session",
                    alternativeNames: [
                        "avg session duration",
                        "session time",
                        "average time on site",
                        "time on site",
                        "duration",
                    ],
                },
            ],
        },
        {
            description: "Trends in page views and conversions over time",
            name: "webTrendsPageViewsConversionsOverTime",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the metric",
                    alternativeNames: [
                        "metric date",
                        "date recorded",
                        "recorded date",
                        "event date",
                    ],
                },
                {
                    name: "pageViews",
                    description: "Number of page views",
                    alternativeNames: [
                        "page views",
                        "views",
                        "page impressions",
                        "page hits",
                    ],
                },
                {
                    name: "conversionRate",
                    description: "Conversion rate",
                    alternativeNames: [
                        "conversion rate",
                        "conversions",
                        "conversion percentage",
                        "conversion",
                    ],
                },
            ],
        },
        {
            description: "Autocorrelation Analysis for Web Metrics",
            name: "webAutocorrelationAnalysisWebMetrics",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the metric",
                },
                {
                    name: "pageViews",
                    description: "Number of page views",
                },
                {
                    name: "conversionRate",
                    description: "Conversion rate",
                },
            ],
        },
        {
            description: "Peak Traffic Times",
            name: "webPeakTrafficTimes",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the metric",
                },
                {
                    name: "pageViews",
                    description: "Number of page views",
                },
                {
                    name: "conversionRate",
                    description: "Conversion rate",
                },
            ],
        },
        {
            description: "Web Metrics Trend Analysis",
            name: "webMetricsTrendAnalysis",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the metric",
                },
                {
                    name: "pageViews",
                    description: "Number of page views",
                },
                {
                    name: "conversionRate",
                    description: "Conversion rate",
                },
            ],
        },
    ],
};
