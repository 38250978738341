import React, { useState, useEffect } from 'react';
import { Box, Typography, Tooltip, LinearProgress } from '@mui/material';
import {
    PlayCircleOutline,
    StopCircleOutlined,
    ErrorOutline,
    FiberManualRecord,
    BrokenImage,
} from '@mui/icons-material'; // Update imports
import { keyframes } from '@mui/system';
import { useSSE, useSSEIsConnected } from './sse-context';

const ssePulse = keyframes`
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.5); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
`;

// Define the type for sort progress
type SortProgressType = {
    progress: number;
    groupSize: number;
    sortedGroupsCount: number;
    unsortedGroupsCount: number;
    unsortedPairsCount: number;
    estimatedRemainingComparisons: number;
    estimatedTotalComparisons: number;
};

// SortProgress Component
const SortProgress: React.FC<{ sortProgress: SortProgressType | null }> = ({ sortProgress }) => {
    if (!sortProgress) return null;

    return (
        <Tooltip
            title={
                <Box>
                    <Typography>Progress: {sortProgress.progress}%</Typography>
                    <Typography>Group Size: {sortProgress.groupSize}</Typography>
                    <Typography>Sorted Groups: {sortProgress.sortedGroupsCount}</Typography>
                    <Typography>Unsorted Groups: {sortProgress.unsortedGroupsCount}</Typography>
                    <Typography>Unsorted Pairs: {sortProgress.unsortedPairsCount}</Typography>
                    <Typography>
                        Estimated Remaining Comparisons: {sortProgress.estimatedRemainingComparisons}
                    </Typography>
                    <Typography>
                        Estimated Total Comparisons: {sortProgress.estimatedTotalComparisons}
                    </Typography>
                </Box>
            }
        >
            <Box sx={{ minWidth: 100, mr: 2 }}>
                <LinearProgress variant="determinate" value={sortProgress.progress} />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                    <Typography variant="caption">{sortProgress.groupSize}</Typography>
                    <Typography variant="caption">{sortProgress.sortedGroupsCount}</Typography>
                    <Typography variant="caption">{sortProgress.unsortedPairsCount}</Typography>
                </Box>
            </Box>
        </Tooltip>
    );
};

const WorkflowStatus: React.FC = () => {
    const subscribe = useSSE();
    const isConnected = useSSEIsConnected();
    const [isWorkflowRunning, setIsWorkflowRunning] = useState(false);
    const [workflowFailed, setWorkflowFailed] = useState(false);
    const [sortProgress, setSortProgress] = useState<SortProgressType | null>(null);
    const [isReceivingEvents, setIsReceivingEvents] = useState(false);

    useEffect(() => {
        const handleMessage = (message: { event: string; update: any }) => {
            if (message.event === 'workflow-state-changed') {
                if (message.update.newState === 'running-sort') {
                    setIsWorkflowRunning(true);
                    setWorkflowFailed(false);
                    setSortProgress({
                        progress: 0,
                        groupSize: 0,
                        sortedGroupsCount: 0,
                        unsortedGroupsCount: 0,
                        unsortedPairsCount: 0,
                        estimatedRemainingComparisons: 0,
                        estimatedTotalComparisons: 0,
                    });
                } else if (message.update.newState === 'failed') {
                    setWorkflowFailed(true);
                    setIsWorkflowRunning(false);
                } else {
                    setIsWorkflowRunning(message.update.newState !== 'completed');
                    if (message.update.newState === 'completed') {
                        setSortProgress(null);
                    }
                }
            } else if (message.event === 'workflow-completed') {
                setIsWorkflowRunning(false);
            } else if (message.event === 'sort-progress') {
                setSortProgress(message.update);
            } else if (message.event === 'sort-completed') {
                setSortProgress(null);
            }
            setIsReceivingEvents(true);
            // Allow animation to complete before resetting
            setTimeout(() => {
                setIsReceivingEvents(false);
            }, 1500);
        };

        const unsubscribe = subscribe(handleMessage);

        return () => {
            unsubscribe();
        };
    }, [subscribe]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            {/* Sort Progress */}
            <SortProgress sortProgress={sortProgress} />

            {/* Workflow State */}
            <Box sx={{ ml: 2 }}>
                <Tooltip
                    title={
                        workflowFailed
                            ? "Workflow has failed"
                            : isWorkflowRunning
                            ? "Workflow is running"
                            : "Workflow is stopped"
                    }
                >
                    {workflowFailed ? (
                        <ErrorOutline color="error" />
                    ) : isWorkflowRunning ? (
                        <PlayCircleOutline sx={{ animation: `${ssePulse} 2s infinite` }} />
                    ) : (
                        <StopCircleOutlined />
                    )}
                </Tooltip>
            </Box>

            {/* SSE Connection State */}
            <Box sx={{ ml: 2 }}>
                <Tooltip
                    title={
                        isConnected
                            ? isReceivingEvents
                                ? "Connected and receiving events"
                                : "Connected"
                            : "Disconnected"
                    }
                >
                    {isConnected ? (
                        isReceivingEvents ? (
                            <FiberManualRecord
                                sx={{
                                    animation: `${ssePulse} 1.5s infinite`,
                                }}
                            />
                        ) : (
                            <FiberManualRecord />
                        )
                    ) : (
                        <BrokenImage color="error" />
                    )}
                </Tooltip>
            </Box>
        </Box>
    );
};

export default React.memo(WorkflowStatus);
