import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import {
    AppBar,
    Avatar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import { connection } from './auth-context';
import { useSSE } from './sse-context';
import WorkflowStatus from './workflow-status';
import { useLocation, Link as RouterLink } from 'react-router-dom';

const mockUser = {
    name: 'John Doe',
    avatarUrl: 'https://via.placeholder.com/40',
};

const drawerWidth = 240;

const getEnvironmentName = () => {
    if (typeof window === 'undefined') {
        return null;
    }

    const hostname = window.location.hostname;
    const parts = hostname.split('.');

    if (parts.length < 3) {
        return null;
    }

    const subdomain = parts[0].toLowerCase();

    if (subdomain === 'www') {
        return null;
    }

    return subdomain;
};

export const HubLayout: React.FC = React.memo(() => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const environmentName = React.useMemo(() => getEnvironmentName(), []);

    const handleMenuOpen = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = React.useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDrawerToggle = React.useCallback(() => {
        setMobileOpen(!mobileOpen);
    }, [mobileOpen]);

    const handleLogout = React.useCallback(async () => {
        try {
            console.log('Logging out...');
            connection.logout()
        } catch (error) {
            console.error('Logout failed:', error);
        } finally {
            handleMenuClose();
        }
    }, []);

    const isMenuItemSelected = (path: string) => {
        if (path === '/projects') {
            return location.pathname === path || location.pathname.startsWith('/project/');
        }
        return location.pathname === path;
    };

    const drawerContent = React.useMemo(() => {
        const menuItems = [
            { text: 'Home', path: '/' },
            { text: 'Business Info', path: '/business-info' },
            { text: 'Business Integrations', path: '/business-integrations' },
            { text: 'Business Data Import', path: '/business-data-import' },
            { text: 'Projects', path: '/projects' },
            { text: 'Archived Projects', path: '/archived' },
            { text: 'User Settings', path: '/user-settings' },
        ];

        return (
            <div>
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        {menuItems.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton
                                    component={RouterLink}
                                    to={item.path}
                                    selected={isMenuItemSelected(item.path)}
                                >
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </div>
        );
    }, [location.pathname]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            <AppBar position="fixed">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
                        Project Hub
                    </Typography>

                    {environmentName && (
                        <Box
                            sx={{
                                mr: 2,
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                                padding: '4px 8px',
                                borderRadius: '4px',
                            }}
                        >
                            <Typography variant="body2" color="inherit">
                                {environmentName} environment
                            </Typography>
                        </Box>
                    )}

                    <WorkflowStatus />

                    <IconButton onClick={handleMenuOpen} color="inherit">
                        <Avatar alt={mockUser.name} src={mockUser.avatarUrl} />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => navigate('/user-settings')}>Account Settings</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="navigation"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'block', md: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawerContent}
                </Drawer>

                <Drawer
                    variant="permanent"
                    open
                    sx={{
                        display: { xs: 'none', sm: 'none', md: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawerContent}
                </Drawer>
            </Box>

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Toolbar />
                <Outlet />
            </Box>
        </Box>
    );
});
