import { SupportedImportType } from "../supported-data-import-types";

export const demographicsImport: SupportedImportType = {
    value: "demographics",
    label: "Customer Demographics",
    description: "Information on age, gender, location, income level, and education.",
    analyses: [
        {
            description: "Demographic distribution (charts)",
            name: "demographicsDemographicDistribution",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "age",
                    description: "Age of the customer",
                    alternativeNames: [
                        "customer age",
                        "age group",
                        "birth year",
                        "age range",
                    ],
                },
                {
                    name: "gender",
                    description: "Gender of the customer",
                    alternativeNames: [
                        "sex",
                        "customer gender",
                        "gender identity",
                    ],
                },
            ],
        },
        {
            description: "Segmentation analysis",
            name: "demographicsSegmentationAnalysis",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "age",
                    description: "Age of the customer",
                    alternativeNames: [
                        "customer age",
                        "age group",
                        "birth year",
                        "age range",
                    ],
                },
                {
                    name: "gender",
                    description: "Gender of the customer",
                    alternativeNames: [
                        "sex",
                        "customer gender",
                        "gender identity",
                    ],
                },
                {
                    name: "location",
                    description: "Location of the customer",
                    alternativeNames: [
                        "city",
                        "state",
                        "region",
                        "country",
                        "zip code",
                        "postal code",
                        "address",
                        "geolocation",
                    ],
                },
            ],
        },
        {
            description: "Correlation analysis between demographics and purchasing behavior",
            name: "demographicsCorrelationAnalysisBetweenDemographicsAndPurchasingBehavior",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "age",
                    description: "Age of the customer",
                    alternativeNames: [
                        "customer age",
                        "age group",
                        "birth year",
                        "age range",
                    ],
                },
                {
                    name: "gender",
                    description: "Gender of the customer",
                    alternativeNames: [
                        "sex",
                        "customer gender",
                        "gender identity",
                    ],
                },
                {
                    name: "incomeLevel",
                    description: "Income level of the customer",
                    alternativeNames: [
                        "income",
                        "salary",
                        "earnings",
                        "household income",
                        "annual income",
                    ],
                },
            ],
        },
    ],
};
