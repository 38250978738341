
import React, { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';
import { Snackbar, Alert, AlertColor, SnackbarCloseReason } from '@mui/material';

// Define the shape of the context
interface SnackbarContextType {
    showSnackbar: (message: string, severity: AlertColor) => void;
}

// Create the context with a default value
const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

// Custom hook to use the Snackbar context
export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

// Provider component
interface SnackbarProviderProps {
    children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [severity, setSeverity] = useState<AlertColor>('success');

    const showSnackbar = useCallback(
        (msg: string, sev: AlertColor) => {
            setMessage(msg);
            setSeverity(sev);
            setOpen(true);
        },
        []
    );

    const contextValue = useMemo(
        () => ({
            showSnackbar,
        }),
        [showSnackbar]
    );

    // Handler for Snackbar's onClose
    const handleSnackbarClose = (
        event: any, // React.SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // Handler for Alert's onClose
    const handleAlertClose = (
        event?: any, // React.SyntheticEvent<Element, Event>,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleAlertClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
