import { SupportedImportType } from "../supported-data-import-types";

export const marketResearchImport: SupportedImportType = {
    value: "market_research",
    label: "Market Research Data",
    description: "Data from surveys, focus groups, and market analysis reports.",
    analyses: [
        {
            description: "Frequency analysis of responses",
            name: "marketFrequencyAnalysisOfResponses",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "surveyQuestion",
                    description: "Survey question",
                },
                {
                    name: "responses",
                    description: "Responses to the survey question",
                },
            ],
        },
        {
            description: "Cross-tabulation with demographics",
            name: "marketCrossTabulationWithDemographics",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "surveyQuestion",
                    description: "Survey question",
                },
                {
                    name: "responses",
                    description: "Responses to the survey question",
                },
                {
                    name: "demographics",
                    description: "Demographics of the respondents",
                },
            ],
        },
        {
            description: "Trend analysis if applicable",
            name: "marketTrendAnalysisIfApplicable",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "surveyQuestion",
                    description: "Survey question",
                },
                {
                    name: "responses",
                    description: "Responses to the survey question",
                },
            ],
        },
    ],
};
