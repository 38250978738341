import { SupportedImportType } from "../supported-data-import-types";

export const contentPerformanceImport: SupportedImportType = {
    value: "content_performance",
    label: "Content Performance Data",
    description: "Metrics on blog posts, videos, infographics, and other content types, including views, shares, and engagement rates.",
    analyses: [
        {
            description: "Content type performance comparison",
            name: "contentContentTypePerformanceComparison",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "contentType",
                    description: "Type of the content",
                },
                {
                    name: "views",
                    description: "Number of views",
                },
                {
                    name: "shares",
                    description: "Number of shares",
                },
                {
                    name: "engagementRate",
                    description: "Engagement rate of the content",
                },
            ],
        },
        {
            description: "Engagement trends over time",
            name: "contentEngagementTrendsOverTime",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "contentType",
                    description: "Type of the content",
                },
                {
                    name: "views",
                    description: "Number of views",
                },
                {
                    name: "shares",
                    description: "Number of shares",
                },
                {
                    name: "engagementRate",
                    description: "Engagement rate of the content",
                },
            ],
        },
        {
            description: "Correlation between views and conversions",
            name: "contentCorrelationBetweenViewsAndConversions",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "contentType",
                    description: "Type of the content",
                },
                {
                    name: "views",
                    description: "Number of views",
                },
                {
                    name: "shares",
                    description: "Number of shares",
                },
                {
                    name: "engagementRate",
                    description: "Engagement rate of the content",
                },
            ],
        },
    ],
};
