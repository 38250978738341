import { SupportedImportType } from "../supported-data-import-types";

export const feedbackImport: SupportedImportType = {
    value: "feedback",
    label: "Customer Feedback and Reviews",
    description: "Data from customer surveys, product reviews, and customer ratings.",
    analyses: [
        {
            description: "Average ratings over time",
            name: "feedbackAverageRatingsOverTime",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "productName",
                    description: "Name of the product or service",
                },
                {
                    name: "rating",
                    description: "Customer rating",
                },
                {
                    name: "reviewDate",
                    description: "Date of the review",
                },
            ],
        },
        {
            description: "Sentiment analysis of comments",
            name: "feedbackSentimentAnalysisOfComments",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "productName",
                    description: "Name of the product or service",
                },
                {
                    name: "customerComments",
                    description: "Comments from the customer",
                },
            ],
        },
        {
            description: "Comparison of feedback across products/services",
            name: "feedbackComparisonOfFeedbackAcrossProductsServices",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "productName",
                    description: "Name of the product or service",
                },
                {
                    name: "rating",
                    description: "Customer rating",
                },
            ],
        },
    ],
};
