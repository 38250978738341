import { SupportedImportType } from "../supported-data-import-types";

export const socialMediaImport: SupportedImportType = {
    value: "social_media",
    label: "Social Media Engagement Metrics",
    description: "Metrics including likes, shares, comments, and follower growth across various platforms.",
    analyses: [
        {
            name: "socialEngagementRatePerPost",
            description: "Engagement rate per post",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the post",
                },
                {
                    name: "platform",
                    description: "Social media platform",
                },
                {
                    name: "likes",
                    description: "Number of likes",
                },
                {
                    name: "shares",
                    description: "Number of shares",
                },
                {
                    name: "comments",
                    description: "Number of comments",
                },
            ],
        },
        {
            name: "socialFollowerGrowthTrend",
            description: "Trend analysis of follower growth",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the post",
                },
                {
                    name: "platform",
                    description: "Social media platform",
                },
                {
                    name: "followerGrowth",
                    description: "Growth in followers",
                },
            ],
        },
        {
            name: "socialPlatformPerformanceComparison",
            description: "Performance comparison across platforms",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "date",
                    description: "Date of the post",
                },
                {
                    name: "platform",
                    description: "Social media platform",
                },
                {
                    name: "likes",
                    description: "Number of likes",
                },
                {
                    name: "shares",
                    description: "Number of shares",
                },
                {
                    name: "comments",
                    description: "Number of comments",
                },
            ],
        },
    ],
};
