import { SupportedImportType } from "../supported-data-import-types";

export const stockImport: SupportedImportType = {
    value: "stock",
    label: "Stock Levels",
    description:
        "Real-time inventory data, restock dates, and historical stock levels.",
    analyses: [
        {
            description: "Stock-out rates",
            name: "stockStockOutRates",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "description",
                    description:
                        "Human readable SKU or product name or description",
                    alternativeNames: [
                        "product description",
                        "item description",
                        "product name",
                        "item name",
                        "description",
                        "name",
                    ],
                },
                {
                    name: "sku",
                    description: "Stock Keeping Unit unique ID",
                    alternativeNames: [
                        "SKU",
                        "sku number",
                        "product code",
                        "item number",
                        "item code",
                        "product id",
                        "stock code",
                        "article number",
                    ],
                },
                {
                    name: "quantityOnHand",
                    description: "The current quantity of stock on hand.",
                    alternativeNames: [
                        "stock level",
                        "stock quantity",
                        "quantity in hand",
                        "current inventory",
                        "available stock",
                        "inventory level",
                        "qty available",
                        "in stock",
                    ],
                },
            ],
        },
        {
            description: "Inventory aging analysis",
            name: "stockInventoryAgingAnalysis",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit",
                    alternativeNames: [
                        "SKU",
                        "sku number",
                        "product code",
                        "item number",
                        "item code",
                        "product id",
                        "stock code",
                        "article number",
                    ],
                },
                {
                    name: "lastRestockDate",
                    description: "Date of the last restock",
                    alternativeNames: [
                        "restock date",
                        "last restock",
                        "replenishment date",
                        "last replenishment",
                        "last stocked date",
                    ],
                },
            ],
        },
        {
            description: "Historical stock trends",
            name: "stockHistoricalStockTrends",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit",
                    alternativeNames: [
                        "SKU",
                        "sku number",
                        "product code",
                        "item number",
                        "item code",
                        "product id",
                        "stock code",
                        "article number",
                    ],
                },
                {
                    name: "quantityOnHand",
                    description: "Historical stock levels",
                    alternativeNames: [
                        "stock level",
                        "stock quantity",
                        "inventory level",
                        "qty on hand",
                        "quantity available",
                        "available stock",
                    ],
                },
            ],
        },
        {
            description: "Autocorrelation Analysis",
            name: "stockAutocorrelationAnalysis",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit identifier",
                    alternativeNames: ["productCode", "itemID"],
                },
                {
                    name: "quantityOnHand",
                    description: "Quantity of items on hand",
                    alternativeNames: ["stockLevel", "quantity"],
                },
                {
                    name: "timestamp",
                    description: "Date or time of the stock record",
                    alternativeNames: ["date", "time"],
                },
            ],
        },
        {
            description: "Peak Stock Times",
            name: "stockPeakStockTimes",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit identifier",
                    alternativeNames: ["productCode", "itemID"],
                },
                {
                    name: "quantityOnHand",
                    description: "Quantity of items on hand",
                    alternativeNames: ["stockLevel", "quantity"],
                },
                {
                    name: "timestamp",
                    description: "Date or time of the stock record",
                    alternativeNames: ["date", "time"],
                },
            ],
        },

        {
            description: "Peak Stock Dates",
            name: "stockPeakStockDates",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit identifier",
                    alternativeNames: ["productCode", "itemID"],
                },
                {
                    name: "quantityOnHand",
                    description: "Quantity of items on hand",
                    alternativeNames: ["stockLevel", "quantity"],
                },
                {
                    name: "timestamp",
                    description: "Date or time of the stock record",
                    alternativeNames: ["date", "time"],
                },
            ],
        },

        {
            description: "Stock Level Trend Analysis",
            name: "stockStockLevelTrendAnalysis",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit identifier",
                    alternativeNames: ["productCode", "itemID"],
                },
                {
                    name: "quantityOnHand",
                    description: "Quantity of items on hand",
                    alternativeNames: ["stockLevel", "quantity"],
                },
                {
                    name: "timestamp",
                    description: "Date or time of the stock record",
                    alternativeNames: ["date", "time"],
                },
            ],
        },
    ],
};
