// ProjectInner.tsx

import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
    Box,
    Grid,
    TextField,
    Button,
    Typography,
    LinearProgress,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormControl,
    FormLabel,
    Checkbox,
    Switch,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useParams, useNavigate } from 'react-router-dom';
import { MudlarkInterestOverTimeData, MudlarkTopic, Workflow } from '@mudlark/common';

import { useWorkflowId, WorkflowIdProvider } from './workflow-id-provider';
import StatisticsSection from './statistics-section';
import TimeSeriesChart from './time-series-chart';

import ProjectStepper from './project-stepper';
import HelpText from './help-text';
import Chatbot from './chatbot';
import TermManager from './term-manager';
import { getAPIClient } from './client-id';

import { useSnackbar } from './snackbar-context';
import { useSSE } from './sse-context'; // Import useSSE
// import AnalysisSection from './analysis-section';
import RecommendationsSection from './recommendations-section';
import AnalysisSection from './analysis-section';
import { workflowStateDescriptions } from './term';

// Add custom arraysEqual function
const arraysEqual = (a: boolean[], b: boolean[]) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export const Project: React.FC = () => {
    const { workflowId } = useParams<{ workflowId: string }>();
    const navigate = useNavigate();

    // Redirect to /projects if workflowId is missing
    useEffect(() => {
        if (!workflowId) {
            navigate('/projects', { replace: true });
        }
    }, [workflowId, navigate]);

    return (
        <WorkflowIdProvider workflowId={workflowId}>
            {/* WorkflowIdProvider will only render ProjectInner if a valid workflow ID is available:
                - useWorkflowId is guaranteed to return an ID
                - that workflow ID is guaranteed to reference a workflow that exists
            */}
            <ProjectInner />
        </WorkflowIdProvider>
    );
};

// Define action types for the reducer
type Action =
    | { type: 'SET_EXPANDED_SECTIONS'; expanded: boolean[] }
    | { type: 'NEXT_STEP'; }
    | { type: 'SET_STEP'; step: number };

// Define the initial state
type State = {
    expandedSections: boolean[];
    activeStep: number;
};

// Initial state with all sections collapsed except the first one
const initialState: State = {
    expandedSections: [true, ...Array(5).fill(false)],
    activeStep: 0,
};

// Reducer function to manage accordion and step state
function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'SET_EXPANDED_SECTIONS':
            return {
                ...state,
                expandedSections: action.expanded.length === 6 ? action.expanded : [true, ...Array(5).fill(false)],
            };

        case 'NEXT_STEP': {
            const nextStep = state.activeStep + 1;
            const updatedExpandedSections = state.expandedSections.map((expanded, idx) =>
                idx === nextStep ? true : expanded
            );

            return {
                expandedSections: updatedExpandedSections,
                activeStep: nextStep,
            };
        }

        case 'SET_STEP': {
            const updatedExpandedSections = state.expandedSections.map((expanded, idx) =>
                idx === action.step ? true : expanded
            );

            return {
                expandedSections: updatedExpandedSections,
                activeStep: action.step,
            };
        }

        default:
            return state;
    }
}

const trendSourcesList = [
    { name: 'Google', value: 'google' },
    { name: 'TikTok', value: 'tiktok' },
    { name: 'Instagram', value: 'instagram' },
    { name: 'Reddit', value: 'reddit' },
    { name: 'Meta', value: 'meta' },
    { name: 'LinkedIn', value: 'linkedin' },
    { name: 'Your web analytics data', value: 'web_analytics' },
    { name: 'Your sales data', value: 'sales_data' },
];

export const ProjectInner: React.FC = () => {
    const workflowId = useWorkflowId(); // this is guaranteed to reference a valid workflow
    
    const [canProceedToInterestOverTimeQuery, setCanProceedToInterestOverTimeQuery] = useState<boolean>(false);

    // console.log(`rendering project for project ID: ${workflowId}, can proceed to interest over time query: ${canProceedToInterestOverTimeQuery}`);

    const { showSnackbar } = useSnackbar(); // Access the Snackbar context

    // const [fromDate, setFromDate] = useState<string | null>(null);
    // const [toDate, setToDate] = useState<string | null>(null);
    const [title, setTitle] = useState<string>('');
    // const [context, setContext] = useState<string>('');
    // const [details, setDetails] = useState<string>('');
    // const [topics, setTopics] = useState<MudlarkTopic[] | null>(null);
    const [analysis, setAnalysis] = useState<string | null>(null);
    const [recommendations, setRecommendations] = useState<string | null>(null);
    // const [sortedKeywords, setSortedKeywords] = useState<string[]>([]);
    const [results, setResults] = useState<MudlarkInterestOverTimeData | null>(null);
    // const [sortProgress, setSortProgress] = useState<number | null>(null);
    // const [activeStep, setActiveStep] = useState<number>(0);

    const [selectedPeriod, setSelectedPeriod] = useState<string>('7');
    const [trendSources, setTrendSources] = useState<string[]>([]);
    // const [autoTermsGeneration, setAutoTermsGeneration] = useState<boolean>(false);
    // const [generateSearchTopics, setGenerateSearchTopics] = useState<boolean>(true); // Controlled by switch
    // const [contextTouched, setContextTouched] = useState<boolean>(false);

    const [isChatFinished, setIsChatFinished] = useState<boolean>(false);

    const [state, dispatch] = useReducer(reducer, initialState);

    // Add state to hold searchTerms from the workflow
    const [initialSearchTerms, setInitialSearchTerms] = useState<Workflow['searchTerms'] | null>(null);

    // Add state to store the workflow data
    const [workflow, setWorkflow] = useState<Workflow | null>(null);
    // Add state variable for chatId
    const [chatId, setChatId] = useState<string | null>(null);

    // Add handler function to update expansionStates
    const getHandleAccordionChange = (stepIndex: number) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        if (stepIndex > state.activeStep) {
            showSnackbar('Please complete the previous sections first.', 'error');
            return;
        }

        const updatedExpandedSections = [...state.expandedSections];
        updatedExpandedSections[stepIndex] = isExpanded;
        dispatch({ type: 'SET_EXPANDED_SECTIONS', expanded: updatedExpandedSections });
    };

    const handleChatFinished = async (finished: boolean) => {
        if (finished) {
            setIsChatFinished(finished);
            await updateWorkflow('chatIsFinished', finished);
        }
    };

    // Handle next step button click with validation
    const handleNextStep = async (targetStep: number) => {
        // Validate all steps up to targetStep - 1
        for (let step = 0; step < targetStep; step++) {
            let isValid = false;
            switch (step) {
                case 0:
                    isValid = validateStep1();
                    break;
                case 1:
                    isValid = validateStep2();
                    break;
                case 2:
                    isValid = validateStep3();
                    break;
                case 3:
                    isValid = validateStep4();
                    break;
                default:
                    isValid = true;
            }
            if (!isValid) {
                showSnackbar(`Step ${step + 1} is not valid. Please complete it before proceeding.`, 'error');
                return;
            }
        }

        // If all validations pass, set the active step to targetStep
        dispatch({ type: 'SET_STEP', step: targetStep });
        // setActiveStep(targetStep);
        updateWorkflow('activeStep', targetStep);
    };

    const handleStepNavigation = (step: number) => {
        if (step > state.activeStep) {
            showSnackbar('Please complete the previous sections first.', 'error');
            return;
        }

        dispatch({ type: 'SET_STEP', step });
        updateWorkflow('activeStep', step);
    };

    const validateStep1 = (): boolean => {
        if (title.trim() === '' || !isChatFinished) {
            showSnackbar('Please provide a title and complete the chat before proceeding.', 'error');
            return false;
        }
        return true;
    };

    const validateStep2 = (): boolean => {
        if (trendSources.length === 0) {
            showSnackbar('Please select at least one trend source before proceeding.', 'error');
            return false;
        }
        return true;
    };

    // Define a mapping from field names to human-readable descriptions
    const fieldDescriptions: Record<string, string> = {
        'title': 'Project title',
        'activeStep': 'Active step',
        'context': 'Context',
        'details': 'Details',
        'trendSources': 'Trend sources',
        'selectedPeriod': 'Selected period',
        'toDate': 'End date',
        'fromDate': 'Start date',
        'expandedSections': 'Expanded sections',
        'chatIsFinished': 'Chat completion status',
        'chatId': 'Chat ID',
        'canProceedToInterestOverTimeQuery': 'Processing status',
        // Add any other fields as necessary
    };

    const updateWorkflow = useCallback(
        async (field: string, value: any) => {
            if (!workflowId) return;
            try {
                await getAPIClient().patch(
                    `/api/v1/workflow/${workflowId}`,
                    { [field]: value },
                );
                const description = fieldDescriptions[field] ?? field;
                showSnackbar(`${description} updated. Project saved`, 'success');
            } catch (err) {
                console.error(`Error updating workflow field ${field}:`, err);
                showSnackbar('An error occurred while updating the project.', 'error');
                throw err;
            }
        },
        [workflowId, showSnackbar]
    );

    // Callback function to handle process completion
    const handleKeywordProcessComplete = useCallback((canProceed: boolean) => {
        console.log(`Keyword process complete: canProceed=${canProceed}`);
        setCanProceedToInterestOverTimeQuery(canProceed);
    }, [updateWorkflow]);

    // Modify validateStep3 to use canProceedToNextStep
    const validateStep3 = (): boolean => {
        if (!canProceedToInterestOverTimeQuery) {
            showSnackbar('Please ensure search terms are processed before proceeding.', 'error');
            return false;
        }
        return true;
    };

    const validateStep4 = (): boolean => {
        return true; // Allow moving to step 5 after viewing the chart
    };

    const handleTitleChange = (title: string) => {
        setTitle(title);
        updateWorkflow('title', title);
    };

    const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const period = event.target.value;

        const days = parseInt(period, 10);
        const today = new Date();
        const fromDateObj = new Date(today);
        fromDateObj.setDate(today.getDate() - days);

        const toDateStr = today.toISOString().split('T')[0];
        const fromDateStr = fromDateObj.toISOString().split('T')[0];

        setSelectedPeriod(period);
        // setToDate(toDateStr);
        // setFromDate(fromDateStr);

        // Send a single PATCH request to update the workflow with both toDate and fromDate
        updateWorkflowFields({ selectedPeriod: period, toDate: toDateStr, fromDate: fromDateStr });
    };

    const handleTrendSourceChange = (event: React.ChangeEvent<HTMLInputElement>, sourceValue: string) => {
        let updatedSources;
        if (event.target.checked) {
            updatedSources = [...trendSources, sourceValue];
        } else {
            updatedSources = trendSources.filter((s) => s !== sourceValue);
        }
        setTrendSources(updatedSources);
        updateWorkflow('trendSources', updatedSources);
    };

    // useCallback may be pointless - I'm not sure if workflowId can ever change
    const sendGenerateTopicsRequest = useCallback(
        async (terms: string[]) => {
            try {
                await getAPIClient().post(
                    `/api/v1/workflow/${workflowId}/generate-topics`,
                    { keywords: terms },
                );
                showSnackbar('Search topics generated successfully.', 'success');
            } catch (err) {
                console.error('Error sending generate topics request:', err);
                showSnackbar('An error occurred while sending the generate topics request.', 'error');
                throw err;
            }
        },
        [workflowId, showSnackbar]
    );

    // useCallback may be pointless - I'm not sure if workflowId can ever change
    const sendInterestOverTimeRequest = useCallback(async () => {
        try {
            await getAPIClient().post(
                `/api/v1/workflow/${workflowId}/interest-over-time`,
                {}, // Removed the keywords parameter
            );
            showSnackbar('Interest over time data fetched successfully.', 'success');
        } catch (err) {
            console.error('Error sending interest over time request:', err);
            showSnackbar('An error occurred while sending the interest over time request.', 'error');
            throw err;
        }
    }, [workflowId, showSnackbar]);

    const updateWorkflowFields = useCallback(
        async (fields: Record<string, any>) => {
            if (!workflowId) return;

            try {
                await getAPIClient().patch(
                    `/api/v1/workflow/${workflowId}`,
                    fields,
                );
                // Update the local workflow state with the new fields
                setWorkflow(prevWorkflow => {
                    if (prevWorkflow) {
                        return { ...prevWorkflow, ...fields };
                    } else {
                        return fields as Workflow;
                    }
                });
                showSnackbar('Project saved', 'success');
            } catch (err) {
                console.error('Error updating workflow fields:', err);
                showSnackbar('An error occurred while updating the project.', 'error');
            }
        },
        [workflowId, showSnackbar]
    );

    const handleWorkflowEvent = useCallback(
        (event: string, update: any) => {
            if (event === 'workflow-updated') {
                // Update the component state with the new workflow data
                setTitle(update.title ?? '');
                // setContext(update.context ?? '');
                // setDetails(update.details ?? '');
                // setToDate(update.toDate);
                // setFromDate(update.fromDate);
                // setTopics(update.topics);
                setAnalysis(update.analysis ?? null);
                setRecommendations(update.recommendations ?? null);
                // setSortedKeywords(update.sortedKeywords ?? []);
                setResults(update.results ?? null);
                // Update other state variables as needed
            } else if (event === 'workflow-state-changed') {
                const { newState } = update as { newState: string };
                const newStateDescription = workflowStateDescriptions[newState] ?? `in unknown state ${newState}`;
                showSnackbar(`${newStateDescription}`, 'info');
            } else if (event === 'workflow-failed') {
                showSnackbar('Project processing failed.', 'error');
            }
        },
        [showSnackbar]
    );

    // const handleSortProgressEvent = useCallback(
    //     (event: string, update: any) => {
    //         if (event === 'sort-progress') {
    //             setSortProgress(update.progress);
    //         }
    //     },
    //     []
    // );

    const subscribe = useSSE();

    useEffect(() => {
        const handleSSEMessage = (message: { event: string, update: any }) => {
            if (message.event === 'interest-search-completed') {
                setChartDataAvailable(true);
                fetchWorkflow();
            } else if (message.event === 'analysis-completed') {
                setAnalysisAvailable(true);
                fetchWorkflow();
            } else if (message.event === 'recommendation-completed') {
                setRecommendationsAvailable(true);
                fetchWorkflow();
            } else if (message.event === 'topic-descriptions') {
                console.log(`Setting descriptions from SSE update: ${JSON.stringify(message.update.descriptions)}`);

                setDescriptions(message.update.descriptions);                
            } else if (message.event.startsWith('workflow-')) {
                handleWorkflowEvent(message.event, message.update);
            }
        };

        const unsubscribe = subscribe(handleSSEMessage);

        return () => {
            unsubscribe();
        };
    }, [subscribe, handleWorkflowEvent]) //, handleSortProgressEvent]);

    // Add fetchWorkflow function
    const fetchWorkflow = useCallback(async () => {
        try {
            const response = await getAPIClient().get<Workflow>(
                `/api/v1/workflow/${workflowId}`
            );

            if (response.data) {
                setTitle(response.data.title ?? '');
                // setContext(response.data.context ?? '');
                // setDetails(response.data.details ?? '');
                // setToDate(response.data.toDate);
                // setFromDate(response.data.fromDate);
                // setTopics(response.data.topics);
                setAnalysis(response.data.analysis ?? null);
                setRecommendations(response.data.recommendations ?? null);
                // setSortedKeywords(response.data.sortedKeywords ?? []);
                setResults(response.data.results ?? null);
                // Set the initial searchTerms from the workflow
                setInitialSearchTerms(response.data.searchTerms ?? null);

                // Initialize state variables from workflow
                setSelectedPeriod(response.data.selectedPeriod ?? '7');
                setIsChatFinished(response.data.chatIsFinished ?? false);

                // Initialize trendSources
                setTrendSources(response.data.trendSources ?? []);

                // Initialize chartDataAvailable, analysisAvailable, recommendationsAvailable
                setChartDataAvailable(!!response.data.results);
                setAnalysisAvailable(!!response.data.analysis);
                setRecommendationsAvailable(!!response.data.recommendations);

                console.log(`Workflow loaded can proceed to interest over time query: ${response.data.canProceedToInterestOverTimeQuery}`);
                setCanProceedToInterestOverTimeQuery(response.data.canProceedToInterestOverTimeQuery ?? false);

                dispatch({ type: 'SET_STEP', step: response.data.activeStep ?? 0 });

                // Initialize expandedSections with a default if not properly set
                const fetchedExpanded = response.data.expandedSections;
                if (Array.isArray(fetchedExpanded) && fetchedExpanded.length === 6) {
                    dispatch({ type: 'SET_EXPANDED_SECTIONS', expanded: fetchedExpanded });
                } else {
                    dispatch({ type: 'SET_EXPANDED_SECTIONS', expanded: [true, ...Array(5).fill(false)] });
                }

                // Store the fetched workflow data
                setWorkflow(response.data);
                setChatId(response.data.chatId ?? null);

                console.log(`Setting descriptions from workflow: ${JSON.stringify(response.data.descriptions)}`);

                setDescriptions(response.data.descriptions ?? {});
            }

            showSnackbar('Project loaded successfully.', 'success');
        } catch (err) {
            console.error('Error fetching workflow:', err);
            showSnackbar('An error occurred while fetching the project data.', 'error');
        }
    }, [workflowId, showSnackbar]);

    useEffect(() => {
        fetchWorkflow();
    }, [fetchWorkflow]);

    const handleTermsAdded = useCallback(async (newTerms: string[]) => {
        if (!workflowId) {
            showSnackbar('Workflow ID is missing. Cannot generate topics.', 'error');
            return;
        }

        try {
            await getAPIClient().post(`/api/v1/workflow/${workflowId}/generate-topics`, {
                keywords: newTerms,
            });
            showSnackbar('Topics generation triggered successfully.', 'success');
        } catch (error: any) {
            console.error('Error triggering topics generation:', error);
            showSnackbar('Failed to trigger topics generation.', 'error');
        }
    }, [workflowId, showSnackbar]);

    const triggerTopicRenewal = useCallback(
        async (termName: string) => {
            try {
                await getAPIClient().post(`/api/v1/workflow/${workflowId}/generate-topics`, {
                    keywords: [termName],
                });
                showSnackbar(`Topics regeneration for "${termName}" initiated.`, 'success');
            } catch (error) {
                console.error(`Error regenerating topics for term "${termName}":`, error);
                showSnackbar(`Failed to regenerate topics for "${termName}".`, 'error');
            }
        },
        [showSnackbar]
    );

    // Implement a fake mechanism to signal 'workflow results are available'
    const [workflowResultsAvailable, setWorkflowResultsAvailable] = useState<boolean>(false);

    useEffect(() => {
        // Simulate workflow results becoming available after 5 seconds
        setTimeout(() => {
            setWorkflowResultsAvailable(true);
        }, 5000);
    }, []);

    const [descriptions, setDescriptions] = useState<{ [keyword: string]: string }>({});

    const [chartDataAvailable, setChartDataAvailable] = useState<boolean>(false);
    const [analysisAvailable, setAnalysisAvailable] = useState<boolean>(false);
    const [recommendationsAvailable, setRecommendationsAvailable] = useState<boolean>(false);

    useEffect(() => {
        if (canProceedToInterestOverTimeQuery) {
            // Check if sections 4 and 5 are already expanded
            if (!state.expandedSections[3] || !state.expandedSections[4]) {
                const updatedExpandedSections = [...state.expandedSections];
                updatedExpandedSections[3] = true; // Expand step 4
                updatedExpandedSections[4] = true; // Expand step 5
                dispatch({ type: 'SET_EXPANDED_SECTIONS', expanded: updatedExpandedSections });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canProceedToInterestOverTimeQuery, dispatch]);

    useEffect(() => {
        handlePeriodChange({ target: { value: selectedPeriod } } as React.ChangeEvent<HTMLInputElement>);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update workflow when expandedSections changes
    useEffect(() => {
        if (workflow?.expandedSections && !arraysEqual(workflow?.expandedSections, state.expandedSections)) {
            updateWorkflow('expandedSections', state.expandedSections);
        }
    }, [state.expandedSections, updateWorkflow, workflow]);

    // Avoid rendering Chatbot before the workflow is loaded
    if (!workflow) {
        return (
            <Box sx={{ p: 3 }}>
                <Typography variant="h6">Loading project...</Typography>
            </Box>
        );
    }

    // Callback to handle chatId received from Chatbot
    const handleChatStarted = async (newChatId: string) => {
        setChatId(newChatId);
        // Persist chatId to the server
        try {
            await updateWorkflow('chatId', newChatId);
        } catch (err) {
            // Handle error if needed
        }
    };

    return (
        <Box sx={{ p: 3 }}>
            {/* Pass expandedSections and a toggle handler to ProjectStepper */}
            <ProjectStepper 
                activeStep={state.activeStep} 
                expandedSections={state.expandedSections} 
                onToggleStep={(step, isExpanded) => {
                    const updatedSections = [...state.expandedSections];
                    updatedSections[step] = isExpanded;
                    dispatch({ type: 'SET_EXPANDED_SECTIONS', expanded: updatedSections });
                }} 
            />

            <Grid container spacing={2}>

                {/* About your business section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[0]} onChange={getHandleAccordionChange(0)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="trend-search-content"
                            id="trend-search-header"
                        >
                            <HelpText text="Tell us about your trend search">
                                <Typography variant="h6">1. Trend search</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <HelpText text="Enter the name of your trend search here e.g., Autumn women’s fashion trends">
                                        <TextField
                                            label="Trend Search Title"
                                            placeholder="Enter trend search title"
                                            fullWidth
                                            value={title}
                                            onChange={(event) => handleTitleChange(event.target.value)}
                                            required
                                        />
                                    </HelpText>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <HelpText text="Select the period you want to analyze">
                                            <FormLabel component="legend">Select Period</FormLabel>
                                        </HelpText>
                                        <RadioGroup
                                            aria-label="period"
                                            name="period"
                                            value={selectedPeriod}
                                            onChange={handlePeriodChange}
                                        >
                                            <FormControlLabel value="7" control={<Radio />} label="Last seven days" />
                                            <FormControlLabel value="30" control={<Radio />} label="Last 30 days" />
                                            <FormControlLabel value="90" control={<Radio />} label="Last three months" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <HelpText text="Let us know a bit about your trend search">
                                        <Chatbot 
                                            persona="trendsSearch" 
                                            userMemoriesName="Business Memories" 
                                            chatMemoriesName="Project Memories" 
                                            chatMemoriesId={workflowId} 
                                            onFinished={handleChatFinished} 
                                            onChatStarted={handleChatStarted}
                                            chatId={chatId}
                                            chatIsFinished={isChatFinished} />
                                    </HelpText>
                                </Grid>

                                <Grid item xs={12}>
                                    <HelpText text={!isChatFinished ? 'Please provide us some context before proceeding' : 'Proceed to trend source selection'}>
                                        <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                            <Button
                                                variant="contained"
                                                onClick={() => handleNextStep(1)}
                                                disabled={!isChatFinished || title.trim() === ''}
                                                data-testid="next-button-trend-search"
                                            >
                                                Next
                                            </Button>
                                        </span>
                                    </HelpText>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Trend sources section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[1]} onChange={getHandleAccordionChange(1)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="trend-sources-content"
                            id="trend-sources-header"
                        >
                            <HelpText text="Select the sources you want to include in your trend analysis">
                                <Typography variant="h6">2. Trend sources</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {trendSourcesList.map((source) => (
                                    <Grid item key={source.value} xs={12} sm={6}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={trendSources.includes(source.value)}
                                                    onChange={(event) => handleTrendSourceChange(event, source.value)}
                                                />
                                            }
                                            label={source.name}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            <HelpText text={!isChatFinished ? 'Please select some trend sources' : 'Proceed to search terms selection'}>
                                <span> {/* Wrapper span to ensure proper button behavior when disabled */}
                                    <Button
                                        variant="contained"
                                        onClick={() => handleNextStep(2)}
                                        disabled={trendSources.length === 0}
                                        data-testid="next-button-trend-sources"
                                    >
                                        Next
                                    </Button>
                                </span>
                            </HelpText>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Search terms section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[2]} onChange={getHandleAccordionChange(2)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="term-manager-content"
                            id="term-manager-header"
                        >
                            <HelpText text="Enter specific search terms for analysis">
                                <Typography variant="h6">3. Enter specific search terms</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    {/* Pass initialSearchTerms to TermManager */}
                                    <TermManager
                                        workflowId={workflowId}
                                        initialSearchTerms={initialSearchTerms}
                                        onTermsAdded={handleTermsAdded}
                                        triggerTopicRenewal={triggerTopicRenewal}
                                        onProcessComplete={handleKeywordProcessComplete}
                                    />
                                </Grid>
                                {/* Remove the separate SearchTermManager and TopicManager components */}
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            await handleNextStep(3);
                                            await sendInterestOverTimeRequest();
                                        }}
                                        disabled={!canProceedToInterestOverTimeQuery}
                                        data-testid="next-button-search-terms"
                                    >
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Visual overview section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[3]} onChange={getHandleAccordionChange(3)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="visual-overview-content"
                            id="visual-overview-header"
                        >
                            <HelpText text="Get a visual overview of search interest over time for your search terms">
                                <Typography variant="h6">4. Visual overview</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            {(chartDataAvailable && results) ? (
                                <TimeSeriesChart data={results} descriptions={descriptions} />
                            ) : (
                                <Typography>Loading chart data...</Typography>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Button variant="outlined" onClick={() => handleNextStep(2)}>
                                    Back
                                </Button>
                                <Button variant="contained" onClick={() => handleNextStep(4)} data-testid="next-button-visual-overview" disabled={!chartDataAvailable}>
                                    Next
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                {/* Analysis and recommendations section */}
                <Grid item xs={12}>
                    <Accordion expanded={state.expandedSections[4]} onChange={getHandleAccordionChange(4)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="analysis-recommendations-content"
                            id="analysis-recommendations-header"
                        >
                            <HelpText text="Get detailed analysis and recommendations for your business">
                                <Typography variant="h6">5. Analysis and recommendations</Typography>
                            </HelpText>
                        </AccordionSummary>
                        <AccordionDetails>
                            {analysisAvailable ? (
                                <>
                                    <AnalysisSection
                                        analysis={analysis}
                                    />

                                    <StatisticsSection results={results} />
                                </>

                            ) : (
                                <Typography>Loading analysis...</Typography>
                            )}
                            {recommendationsAvailable ? (
                                <Box data-testid="recommendations-section">
                                    <RecommendationsSection recommendations={recommendations} />
                                </Box>
                            ) : (
                                <Typography>Loading recommendations...</Typography>
                            )}

                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <Button variant="outlined" onClick={() => handleNextStep(3)}>
                                    Back
                                </Button>
                                <Button variant="contained" onClick={() => handleNextStep(5)}>
                                    Finish
                                </Button>
                            </Box> */}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Box>
    )
};