import { SupportedImportType } from "../supported-data-import-types";

export const keywordImport: SupportedImportType = {
    value: "keyword",
    label: "Keyword, Hashtag, or Search Trend Data",
    description: "Short-tail and long-tail keywords, trending hashtags, and seasonal search trends.",
    analyses: [
        {
            description: "Trend analysis of keyword popularity over time",
            name: "keywordTrendAnalysisOfKeywordPopularityOverTime",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                    alternativeNames: [
                        "search term",
                        "query",
                        "hashtag",
                        "key phrase",
                        "term",
                    ],
                },
                {
                    name: "searchVolume",
                    description: "Search volume of the keyword",
                    alternativeNames: [
                        "search volume",
                        "volume",
                        "search count",
                        "query volume",
                        "keyword volume",
                    ],
                },
            ],
        },
        {
            description: "Seasonal variations in search volume",
            name: "keywordSeasonalVariationsInSearchVolume",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                    alternativeNames: [
                        "search term",
                        "query",
                        "hashtag",
                        "key phrase",
                        "term",
                    ],
                },
                {
                    name: "searchVolume",
                    description: "Search volume of the keyword",
                    alternativeNames: [
                        "search volume",
                        "volume",
                        "search count",
                        "query volume",
                        "keyword volume",
                    ],
                },
            ],
        },
        {
            description: "Performance comparison of related keywords",
            name: "keywordPerformanceComparisonOfRelatedKeywords",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                    alternativeNames: [
                        "search term",
                        "query",
                        "hashtag",
                        "key phrase",
                        "term",
                    ],
                },
                {
                    name: "clickThroughRate",
                    description: "Click-through rate of the keyword",
                    alternativeNames: [
                        "ctr",
                        "click through rate",
                        "clicks per impression",
                        "click rate",
                    ],
                },
            ],
        },
        {
            description: "Autocorrelation Analysis for Keywords",
            name: "keywordAutocorrelationAnalysisForKeywords",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                },
                {
                    name: "searchVolume",
                    description: "Search volume of the keyword",
                },
                {
                    name: "date",
                    description: "Date of the search volume record",
                },
            ],
        },
        {
            description: "Peak Keyword Times",
            name: "keywordPeakKeywordTimes",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                },
                {
                    name: "searchVolume",
                    description: "Search volume of the keyword",
                },
                {
                    name: "date",
                    description: "Date of the search volume record",
                },
            ],
        },
        {
            description: "Peak Keyword Dates",
            name: "keywordPeakKeywordDates",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                },
                {
                    name: "searchVolume",
                    description: "Search volume of the keyword",
                },
                {
                    name: "date",
                    description: "Date of the search volume record",
                },
            ],
        },
        {
            description: "Keyword Trend Analysis",
            name: "keywordKeywordTrendAnalysis",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "keyword",
                    description: "Keyword or hashtag",
                },
                {
                    name: "searchVolume",
                    description: "Search volume of the keyword",
                },
                {
                    name: "date",
                    description: "Date of the search volume record",
                },
            ],
        },
    ],
};
