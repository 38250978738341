import { SupportedImportType } from "../supported-data-import-types";

export const crmImport: SupportedImportType = {
    value: "crm",
    label: "CRM Data",
    description: "Information from Customer Relationship Management systems, including customer interactions, sales pipelines, and support tickets.",
    analyses: [
        {
            description: "Customer lifetime value (CLV)",
            name: "crmCustomerLifetimeValue",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "customerId",
                    description: "ID of the customer",
                },
                {
                    name: "interactionDate",
                    description: "Date of the interaction",
                },
                {
                    name: "salesStage",
                    description: "Stage of the sales process",
                },
            ],
        },
        {
            description: "Sales funnel analysis",
            name: "crmSalesFunnelAnalysis",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "customerId",
                    description: "ID of the customer",
                },
                {
                    name: "interactionDate",
                    description: "Date of the interaction",
                },
                {
                    name: "salesStage",
                    description: "Stage of the sales process",
                },
            ],
        },
        {
            description: "Ticket resolution times",
            name: "crmTicketResolutionTimes",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "customerId",
                    description: "ID of the customer",
                },
                {
                    name: "interactionDate",
                    description: "Date of the interaction",
                },
                {
                    name: "supportTicketStatus",
                    description: "Status of the support ticket",
                },
            ],
        },
    ],
};
