// note this file is symlinked into the user interface project because vite can't import enums from a different project

// IF THIS FAILS TO IMPORT reference terms.ts directly in the user interface project
export enum WorkflowTermStatus {
    NewlyAdded = 'newly_added',
    ReceivedTopics = 'received_topics',
    TopicsSearchComplete = 'topics_search_complete',
    TopicsRated = 'topics_rated',
    TopicsRatingComplete = 'topics_rating_complete',
    InterestProbed = 'interest_probed',
    NoUsableTopics = 'no_usable_topics',
    Complete = 'complete',
    Error = 'error', 
}

// IF THIS FAILS TO IMPORT reference terms.ts directly in the user interface project
export enum WorkflowTermSelectionStatus {
    UserAdded = 'user_added',
    Suggested = 'suggested',
    Pinned = 'pinned',
    Excluded = 'excluded',
}

// apparently can't export this either...
export const workflowStateDescriptions: {[state: string]: string} = {
    'created': "Project created",
    'starting': "Process starting",
    'running-keywords': "Processing your request",
    'running-sort': "Querying search volumes",
    'running-interest-probe': "Probing search interest",
    'running-analysis': "Analysing trend data",
    'stopped': "Stopped",
    'completed': "Processing completed",
    'failed': "Processing failed",
}

