// import { generateId } from '@mudlark/common';

import { nanoid } from "nanoid";

import axios, { AxiosInstance } from "axios";

export function getClientId() {
    let clientId = sessionStorage.getItem('clientId');

    if (!clientId) {
        clientId = nanoid();
        sessionStorage.setItem('clientId', clientId);

        console.log('Generated new client ID:', clientId);
    } else {
        console.log('Using existing client ID:', clientId);
    }

    return clientId;
}

let apiClient: AxiosInstance | null = null;

export function getAPIClient() {
    if (!apiClient) {
        const clientId = getClientId();

        apiClient = axios.create({
            withCredentials: true, // This sets withCredentials for all requests
        });

        // Set the X-Client-ID header for all requests
        apiClient.defaults.headers.common['X-Client-ID'] = clientId;

        console.log('Created new API client with client ID:', clientId);
    }

    return apiClient;
}

export function clearAPIClient() {
    apiClient = null;
}

export function clearClient() {
    sessionStorage.removeItem('clientId');
    clearAPIClient();
}