import React, { useState, useEffect, useRef, useReducer } from "react";
import { Link as RouterLink } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import { nanoid } from "nanoid";

import {
    Box,
    TextField,
    Button,
    Typography,
    LinearProgress,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    SelectChangeEvent,
    ListItemText,
    Grid,
    CircularProgress,
} from "@mui/material";
import Chatbot from "./chatbot";
import { getAPIClient } from "./client-id";
import { useSSE } from "./sse-context";
import { useSnackbar } from "./snackbar-context"; // Adjust the import path as needed
// import { getSupportedDataImportTypes } from '@mudlark/common';

import BlockIcon from "@mui/icons-material/Block";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

import { getSupportedDataImportTypes } from "../../../services/common/src/supported-data-import-types";
import { UserProvider, useUser } from './user-provider';
import BusinessSetupStepper from './business-setup-stepper';

const importTypes = getSupportedDataImportTypes();

// Define the possible analysis states
type AnalysisState =
    | "pending"
    | "checking"
    | "running"
    | "blocked"
    | "completed"
    | "failed";

// Define the structure of an analysis item
interface AnalysisItem {
    name: string;
    description: string;
    state: AnalysisState;
    missingColumns?: string[]; // Add missingColumns property
    summary?: string; // Add summary property
}

// Define actions for the reducer
type Action =
    | { type: "SET_ANALYSES"; analyses: AnalysisItem[] }
    | { type: "SET_ANALYSIS_STATE"; name: string; state: AnalysisState }
    | {
          type: "SET_MISSING_COLUMNS";
          name: string;
          missingColumns: string[];
      }
    | { type: "SET_SUMMARY"; name: string; summary: string } // Add SET_SUMMARY action
    | { type: "RESET" };

// Reducer function to manage analyses state
function analysesReducer(
    state: AnalysisItem[],
    action: Action,
): AnalysisItem[] {
    switch (action.type) {
        case "SET_ANALYSES":
            return action.analyses;
        case "SET_ANALYSIS_STATE":
            return state.map((analysis) =>
                analysis.name === action.name
                    ? { ...analysis, state: action.state }
                    : analysis,
            );
        case "SET_MISSING_COLUMNS":
            return state.map((analysis) =>
                analysis.name === action.name
                    ? { ...analysis, missingColumns: action.missingColumns }
                    : analysis,
            );
        case "SET_SUMMARY":
            return state.map((analysis) =>
                analysis.name === action.name
                    ? { ...analysis, summary: action.summary }
                    : analysis,
            );
        case "RESET":
            return [];
        default:
            return state;
    }
}

const BusinessDataImport: React.FC = () => {
    return (
        <>
            <UserProvider>
                <BusinessDataImportInner />
            </UserProvider>
            <BusinessSetupStepper activeStep={2} />
        </>
    );
}

const BusinessDataImportInner: React.FC = () => {
    // TODO set business setup stepper step complete if appropriate

    const [file, setFile] = useState<File | null>(null);
    const [dataName, setDataName] = useState("");
    const [chatCompleted, setChatCompleted] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [documentMemoriesId, setDocumentMemoriesId] =
        useState<string>(nanoid());
    const [chatId, setChatId] = useState<string | null>(null);
    const [importType, setImportType] = useState("");
    const [selectedTypeInfo, setSelectedTypeInfo] = useState<any>(null);
    const dataId = useRef<string | null>(null);

    const [possibleAnalyses, setPossibleAnalyses] =
        useState<Set<string> | null>(null);

    const [completedAnalyses, setCompletedAnalyses] =
        useState<Set<string> | null>(null);

    const [missingColumns, setMissingColumns] = useState<Set<string> | null>(
        null,
    );

    const subscribe = useSSE();
    const { showSnackbar } = useSnackbar(); // Access the Snackbar context

    const [analyses, dispatch] = useReducer(analysesReducer, []);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            setFile(e.target.files[0]);
            // Pre-populate dataName if it's empty
            if (!dataName) {
                setDataName(e.target.files[0].name);
            }
        }
    };

    const handleChatFinished = (isFinished: boolean) => {
        setChatCompleted(isFinished);
    };

    const handleChatStarted = (newChatId: string) => {
        setChatId(newChatId);
        // Optionally, save chatId to server or local storage here
    };

    const handleImportTypeChange = (e: SelectChangeEvent) => {
        const selectedType = e.target.value;
        setImportType(selectedType);
        // Clear existing analyses
        dispatch({ type: "RESET" });
        if (selectedType !== "other") {
            setChatId(null);
            // Initialize analyses based on the selected import type
            const typeInfo = importTypes.find(
                (type) => type.value === selectedType,
            );
            if (typeInfo) {
                const initialAnalyses = typeInfo.analyses.map(
                    (analysis: any) => ({
                        name: analysis.name,
                        description: analysis.description,
                        state: "pending" as AnalysisState,
                    }),
                );
                dispatch({ type: "SET_ANALYSES", analyses: initialAnalyses });
            }
        }
    };

    useEffect(() => {
        const typeInfo = importTypes.find((type) => type.value === importType);
        setSelectedTypeInfo(typeInfo);
    }, [importType]);

    useEffect(() => {
        const unsubscribe = subscribe((eventData: any) => {
            switch (eventData.event) {
                case "structure-analysed":
                    console.log("Data Import: Data checked:", eventData.update);

                    if (eventData.update.dataId === dataId.current) {
                        const runningAnalyses = eventData.update
                            .possibleAnalyses as string[];
                        const blockedAnalyses = eventData.update
                            .blockedAnalyses as string[];
                        const missingColumnsMap = eventData.update
                            .missingColumns as { [key: string]: string[] };

                        // Update analyses based on the server response
                        runningAnalyses.forEach((name) => {
                            dispatch({
                                type: "SET_ANALYSIS_STATE",
                                name,
                                state: "running",
                            });
                            // Clear missing columns for running analyses
                            dispatch({
                                type: "SET_MISSING_COLUMNS",
                                name,
                                missingColumns: [],
                            });
                        });

                        blockedAnalyses.forEach((name) => {
                            dispatch({
                                type: "SET_ANALYSIS_STATE",
                                name,
                                state: "blocked",
                            });
                            // Set missing columns for blocked analyses
                            dispatch({
                                type: "SET_MISSING_COLUMNS",
                                name,
                                missingColumns: missingColumnsMap[name] || [],
                            });
                        });

                        showSnackbar("Data structure checked.", "success");
                    } else {
                        console.log(
                            "Data Import: Data ID mismatch:",
                            eventData.update.dataId,
                            dataId.current,
                        );

                        showSnackbar("Data ID mismatch.", "error"); // Data ID mismatch message
                    }
                    break;

                case "data-parsed":
                    console.log("Data Import: Data parsed:", eventData.update);

                    if (eventData.update.dataId === dataId.current) {
                        showSnackbar("Data parsed successfully.", "success"); // Data parsed message
                    } else {
                        console.log(
                            "Data Import: Data ID mismatch:",
                            eventData.update.dataId,
                            dataId.current,
                        );

                        showSnackbar("Data ID mismatch.", "error"); // Data ID mismatch message
                    }
                    break;

                case "data-analyzed":
                    console.log(
                        "Data Import: Data analyzed:",
                        eventData.update,
                    );

                    if (eventData.update.dataId === dataId.current) {
                        const completedAnalyses = eventData.update
                            .completedAnalyses as string[];

                        // Update analyses based on completion
                        analyses.forEach((analysis) => {
                            if (analysis.state === "running") {
                                const newState = completedAnalyses.includes(
                                    analysis.name,
                                )
                                    ? "completed"
                                    : "failed";
                                dispatch({
                                    type: "SET_ANALYSIS_STATE",
                                    name: analysis.name,
                                    state: newState,
                                });
                            }
                        });

                        showSnackbar("Data analyzed successfully.", "success"); // Data analyzed message
                    } else {
                        console.log(
                            "Data Import: Data ID mismatch:",
                            eventData.update.dataId,
                            dataId.current,
                        );

                        showSnackbar("Data ID mismatch.", "error"); // Data ID mismatch message
                    }
                    break;

                case "analysis-completed":
                    console.log(
                        "Data Import: Analysis completed:",
                        eventData.update,
                    );

                    if (eventData.update.dataId === dataId.current) {
                        const { name, result } = eventData.update;
                        const summary = result?.summary;
                        const newState = result?.success
                            ? "completed"
                            : "failed";

                        dispatch({
                            type: "SET_ANALYSIS_STATE",
                            name,
                            state: newState,
                        });

                        if (summary) {
                            dispatch({
                                type: "SET_SUMMARY",
                                name,
                                summary,
                            });
                        }

                        if (!result.success && result.error) {
                            showSnackbar(
                                `Analysis "${name}" failed: ${result.error}`,
                                "error",
                            );
                        } else {
                            showSnackbar(
                                `Analysis "${name}" completed successfully.`,
                                "success",
                            );
                        }
                    } else {
                        console.log(
                            "Data Import: Data ID mismatch:",
                            eventData.update.dataId,
                            dataId.current,
                        );
                        showSnackbar("Data ID mismatch.", "error");
                    }
                    break;

                default:
                    console.log(
                        "Data Import: Unhandled event:",
                        eventData.event,
                    );

                    break;
            }
        });

        return () => {
            unsubscribe();
        };
    }, [subscribe, analyses]);

    const handleUpload = async () => {
        console.log("Handle upload...");
        let fileId: string | null = null;

        if (file && dataName) {
            console.log("Uploading file:", file, "with data name:", dataName);

            setUploading(true);
            try {
                // File upload
                const formData = new FormData();
                formData.append("file", file);
                formData.append("name", dataName);

                const result = await getAPIClient().post(
                    "/api/v1/file",
                    formData,
                );

                fileId = result.data.file_id;

                console.log("File uploaded successfully:", fileId);

                showSnackbar("File uploaded successfully.", "success"); // File uploaded message
            } catch (error) {
                console.error("Error uploading file:", error);
                showSnackbar("An error occurred during file upload.", "error"); // Upload error message
                setUploading(false);
                return;
            }

            try {
                console.log("Starting CSV ingestion...");

                // CSV ingestion
                const ingestPayload = {
                    importType,
                    fileId,
                    memoriesId:
                        importType == "other" ? documentMemoriesId : undefined,
                };

                const ingestResponse = await getAPIClient().post(
                    "/api/v1/csv",
                    ingestPayload,
                );

                console.log("CSV ingestion response:", ingestResponse.data);

                const ingestResult = ingestResponse.data;
                dataId.current = ingestResult.dataId;

                // Set all analyses to 'checking' state
                analyses.forEach((analysis) => {
                    dispatch({
                        type: "SET_ANALYSIS_STATE",
                        name: analysis.name,
                        state: "checking",
                    });
                });

                showSnackbar("CSV file is processing.", "success"); // Ingestion message
            } catch (error) {
                console.error("Error ingesting CSV:", error);
                showSnackbar(
                    "An error occurred during CSV ingestion.",
                    "error",
                ); // Ingestion error message
            } finally {
                setUploading(false);
            }
        }
    };

    return (
        <>
            <Box sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Business Data Import
                </Typography>

                {/* Explanatory section grouped */}
                <Box sx={{ mb: 3 }}>
                    <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                            Purpose of Importing Data
                        </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        Importing data provides Mudlark's AI agents with additional
                        context to better understand the current state of your
                        business. This enhanced understanding improves the quality
                        of their analysis and recommendations.
                    </Typography>

                    <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                            Supported Data Types
                        </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                        The dropdown list includes examples of useful data types,
                        but it is not exhaustive. You are welcome to upload other
                        types of data; however, we may not be able to utilize them
                        effectively.
                    </Typography>

                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="h6" gutterBottom>
                            Data Format Requirements
                        </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom component="div">
                        <ul>
                            <li>
                                <strong>Spreadsheet Format:</strong> We expect the
                                data to be in a regularly formatted spreadsheet
                                containing typical data types (see the list below).
                            </li>
                            <li>
                                <strong>Consistency:</strong> For best results,
                                avoid mixing different kinds of data within the same
                                sheet. While our AI agents will do their best to
                                interpret the data provided, maintaining consistency
                                helps ensure more accurate analysis.
                            </li>
                        </ul>
                    </Typography>
                </Box>

                {/* Import Type Select Dropdown */}
                <FormControl fullWidth margin="normal">
                    <InputLabel>Select Import Type</InputLabel>
                    <Select
                        value={importType}
                        label="Select Import Type"
                        onChange={handleImportTypeChange}
                        renderValue={(selected) => {
                            const selectedType = importTypes.find(
                                (type) => type.value === selected,
                            );
                            return selectedType ? selectedType.label : "";
                        }}
                    >
                        {importTypes.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                                <ListItemText
                                    primary={type.label}
                                    secondary={type.description}
                                />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {/* Chatbot */}
                {importType === "other" && (
                    <Box sx={{ my: 2 }}>
                        <Chatbot
                            persona="spreadsheetIngestion"
                            userMemoriesName="My Memories"
                            chatMemoriesName="Data Memories"
                            chatMemoriesId={documentMemoriesId}
                            chatId={chatId}
                            onChatStarted={handleChatStarted}
                            onFinished={handleChatFinished}
                            chatIsFinished={chatCompleted}
                        />
                    </Box>
                )}

                {/* Expected Columns and Analyses */}
                {importType !== "other" && selectedTypeInfo && (
                    <Box sx={{ mt: 3 }}>
                        <Typography variant="h6">Available Analyses</Typography>
                        {analyses.map((analysis) => (
                            <Box key={analysis.name} sx={{ mb: 2 }}>
                                <Typography
                                    variant="subtitle1"
                                    display="flex"
                                    alignItems="center"
                                >
                                    {analysis.description}
                                    {analysis.state === "checking" && (
                                        <CircularProgress
                                            size={20}
                                            sx={{ ml: 1, mr: 1 }}
                                        />
                                    )}
                                    {analysis.state === "running" && (
                                        <CircularProgress
                                            size={20}
                                            sx={{ ml: 1, mr: 1 }}
                                        />
                                    )}
                                    {analysis.state === "blocked" && (
                                        <BlockIcon color="error" sx={{ ml: 1 }} />
                                    )}
                                    {analysis.state === "completed" && (
                                        <CheckCircleIcon
                                            color="success"
                                            sx={{ ml: 1 }}
                                        />
                                    )}
                                    {analysis.state === "failed" && (
                                        <ErrorIcon color="error" sx={{ ml: 1 }} />
                                    )}
                                </Typography>

                                {analysis.summary && (
                                    <ReactMarkdown>
                                        {analysis.summary}
                                    </ReactMarkdown>
                                )}

                                <Typography variant="body2">
                                    Expected Columns:
                                </Typography>
                                <ul>
                                    {(() => {
                                        const currentAnalysis = selectedTypeInfo.analyses.find(
                                            (a: any) => a.name === analysis.name
                                        );
                                        if (!currentAnalysis) return null;

                                        const renderColumn = (column: any, isRequired: boolean) => {
                                            const isMissing = analysis.missingColumns?.includes(column.name);
                                            return (
                                                <li key={column.name}>
                                                    {column.description}{" "}
                                                    {isMissing && isRequired ? (
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="error"
                                                            sx={{
                                                                ml: 1,
                                                                display: "inline-flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <WarningIcon
                                                                fontSize="small"
                                                                sx={{ mr: 0.5 }}
                                                            />
                                                            Missing
                                                        </Typography>
                                                    ) : (
                                                        !isRequired && (
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                color="textSecondary"
                                                                sx={{ ml: 1 }}
                                                            >
                                                                {isMissing ? "(optional, missing)" : "(optional)"}
                                                            </Typography>
                                                        )
                                                    )}
                                                </li>
                                            );
                                        };

                                        return (
                                            <>
                                                {currentAnalysis.requiredColumns.map((column: any) =>
                                                    renderColumn(column, true)
                                                )}
                                                {currentAnalysis.optionalColumns && currentAnalysis.optionalColumns.map((column: any) =>
                                                    renderColumn(column, false)
                                                )}
                                            </>
                                        );
                                    })()}
                                </ul>
                            </Box>
                        ))}
                    </Box>
                )}

                {/* File Upload and Upload Buttons */}
                <input
                    accept=".csv"
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                />

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    {/* Select File Button */}
                    <Grid item xs={12}>
                        <label htmlFor="file-upload">
                            <Button
                                variant="contained"
                                component="span"
                                disabled={
                                    !importType ||
                                    (importType == "other" && !chatCompleted) ||
                                    uploading
                                }
                            >
                                Select File
                            </Button>
                        </label>
                    </Grid>
                    {/* Data Name Input */}
                    <Grid item xs={12}>
                        <TextField
                            label="Data Name"
                            value={dataName}
                            onChange={(e) => setDataName(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    {/* Upload Button */}
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpload}
                            disabled={
                                !importType ||
                                (importType == "other" && !chatCompleted) ||
                                !file ||
                                uploading
                            }
                        >
                            Upload
                        </Button>
                    </Grid>
                </Grid>
                {file && <Typography>{file.name}</Typography>}
                {uploading && <LinearProgress sx={{ mt: 2 }} />}
            </Box>
        </>
    );
};

export default BusinessDataImport;
