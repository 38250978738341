import { SupportedImportType } from "../supported-data-import-types";

export const competitorImport: SupportedImportType = {
    value: "competitor",
    label: "Competitor Data",
    description: "Information on competitors’ products, pricing, marketing strategies, and market positioning.",
    analyses: [
        {
            name: "competitorSwotAnalysis",
            description: "SWOT analysis (Strengths, Weaknesses, Opportunities, Threats)",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "competitorName",
                    description: "Name of the competitor",
                },
                {
                    name: "productServiceOffering",
                    description: "Product or service offering of the competitor",
                },
            ],
        },
        {
            name: "competitorMarketPositioningComparisons",
            description: "Market positioning comparisons",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "competitorName",
                    description: "Name of the competitor",
                },
                {
                    name: "marketShare",
                    description: "Market share of the competitor",
                },
            ],
        },
        {
            name: "competitorPriceComparisonAnalysis",
            description: "Price comparison analysis",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "competitorName",
                    description: "Name of the competitor",
                },
                {
                    name: "pricing",
                    description: "Pricing of the competitor",
                },
            ],
        },
    ],
};
