import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Box,
    Typography,
    Fab,
    Snackbar,
    Alert,
    Paper,
    ListItemButton,
    IconButton,
    ListItemIcon,
    Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import StopIcon from "@mui/icons-material/Stop"; // Imported StopIcon
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useNavigate } from "react-router-dom";
import { getAPIClient } from "./client-id";
import BusinessSetupStepper from "./business-setup-stepper";
import { UserProvider } from "./user-provider";

interface ProjectItem {
    workflowId: string;
    version: number;
    minVersion: number;
    title: string;
    created: number; // Unix timestamp
    edited: number; // Unix timestamp
    state: string;
}

export const ProjectsList: React.FC = () => {
    return (
        <>
            <UserProvider>
                <ProjectsListInner />
            </UserProvider>
            <BusinessSetupStepper activeStep={3} />
        </>
    );
};

export const ProjectsListInner: React.FC = () => {
    const [projects, setProjects] = useState<ProjectItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [creating, setCreating] = useState<boolean>(false);
    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await getAPIClient().get<{
                    workflows: ProjectItem[];
                }>("/api/v1/workflows", {
                    withCredentials: true,
                });

                const { workflows } = response.data;

                const sortedProjects = workflows.sort((a, b) => {
                    // Sort by `edited`, putting null/undefined at the bottom.
                    if (a.edited == null && b.edited != null) return 1;
                    if (a.edited != null && b.edited == null) return -1;

                    if (a.edited !== b.edited) {
                        return b.edited - a.edited; // Descending order
                    }

                    // If `edited` values are equal or both null, sort by `title`, or `id` if `title` is null.
                    const titleA =
                        a.title || `Untitled Project (${a.workflowId})`;
                    const titleB =
                        b.title || `Untitled Project (${b.workflowId})`;
                    return titleA.localeCompare(titleB);
                });
                setProjects(sortedProjects);
            } catch (err) {
                console.error("Error fetching projects:", err);
                if ((err as any).response?.status !== 404) {
                    setError("Failed to load projects.");
                    setShowSnackbar(true);
                }
            } finally {
                setLoading(false);
            }
        };

        fetchProjects();
    }, []);

    const handleProjectClick = (workflowId: string) => {
        navigate(`/project/${workflowId}`);
    };

    const handleAddProjectClick = async () => {
        try {
            setCreating(true);
            const response = await getAPIClient().post<{ workflowId: string }>(
                "/api/v1/workflow",
                {},
                {
                    withCredentials: true,
                },
            );
            const { workflowId } = response.data;
            navigate(`/project/${workflowId}`);
        } catch (err) {
            console.error("Error creating project:", err);
            setError("Failed to create a new project. Please try again.");
            setShowSnackbar(true);
        } finally {
            setCreating(false);
        }
    };

    const handleDeleteProject = async (projectId: string) => {
        try {
            await getAPIClient().delete(`/api/v1/workflow/${projectId}`, {
                withCredentials: true,
            });
            setProjects((prevProjects) =>
                prevProjects.filter(
                    (project) => project.workflowId !== projectId,
                ),
            );
            setSnackbarMessage("Project deleted successfully.");
            setShowSnackbar(true);
        } catch (err) {
            console.error("Error deleting project:", err);
            setSnackbarMessage(
                "Failed to delete the project. Please try again.",
            );
            setShowSnackbar(true);
        }
    };

    const handleStopProject = async (workflowId: string) => {
        try {
            await getAPIClient().post(
                `/api/v1/workflow/${workflowId}/commands`,
                { command: "stop" },
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            // Update the project state locally
            setProjects((prevProjects) =>
                prevProjects.map((project) =>
                    project.workflowId === workflowId
                        ? { ...project, state: "stopped" }
                        : project,
                ),
            );
            setSnackbarMessage("Workflow stopped successfully.");
            setShowSnackbar(true);
        } catch (err) {
            console.error("Error stopping workflow:", err);
            setSnackbarMessage(
                "Failed to stop the workflow. Please try again.",
            );
            setShowSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setShowSnackbar(false);
        setError(null);
        setSnackbarMessage(null);
    };

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        let locale = navigator.language;

        try {
            return date.toLocaleString(locale);
        } catch (e) {
            return date.toLocaleString("en-GB"); // Fallback to GB/UK locale
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    position: "relative",
                    paddingBottom: "16px"
                }}
            >
                {projects.length === 0 ? (
                    <Typography
                        variant="h6"
                        sx={{
                            mt: 2,  // reduced from mt: 4
                            textAlign: "center",
                            color: "text.secondary",
                        }}
                    >
                        No projects found. Create a new project to get started.
                    </Typography>
                ) : (
                    <List sx={{ padding: 2 }}>
                        {projects.map((project) => {
                            const isVersionCompatible =
                                project.version >= project.minVersion;

                            return (
                                <Paper
                                    key={project.workflowId}
                                    elevation={2}
                                    sx={{
                                        mb: 2,
                                        borderRadius: 2,
                                        transition: "0.3s",
                                        "&:hover": { boxShadow: 4 },
                                        opacity: isVersionCompatible ? 1 : 0.5, // Greyed out if incompatible
                                    }}
                                >
                                    <ListItem
                                        secondaryAction={
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {/* Stop Workflow Button */}
                                                <Tooltip title="Stop Workflow">
                                                    <span>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="stop"
                                                            onClick={() =>
                                                                handleStopProject(
                                                                    project.workflowId,
                                                                )
                                                            }
                                                            disabled={
                                                                project.state !==
                                                                    "running" ||
                                                                !isVersionCompatible
                                                            }
                                                            color={
                                                                project.state ===
                                                                "running"
                                                                    ? "secondary"
                                                                    : "default"
                                                            }
                                                        >
                                                            <StopIcon />
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>

                                                {/* Delete Project Button */}
                                                <Tooltip title="Delete Project">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() =>
                                                            handleDeleteProject(
                                                                project.workflowId,
                                                            )
                                                        }
                                                        data-testid={
                                                            project.title
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton
                                            onClick={
                                                isVersionCompatible
                                                    ? () =>
                                                          handleProjectClick(
                                                              project.workflowId,
                                                          )
                                                    : undefined
                                            }
                                            disabled={!isVersionCompatible}
                                            sx={{
                                                padding: 2,
                                                display: "flex",
                                                alignItems: "flex-start",
                                                cursor: isVersionCompatible
                                                    ? "pointer"
                                                    : "not-allowed",
                                            }}
                                        >
                                            <ListItemIcon>
                                                <TrendingUpIcon
                                                    color="primary"
                                                    fontSize="large"
                                                />
                                            </ListItemIcon>

                                            <ListItemText
                                                primary={
                                                    project.title ||
                                                    `Untitled Project (${project.workflowId})`
                                                }
                                                secondary={
                                                    <>
                                                        {"v" + project.version}{" "}
                                                        {/* Added version display */}
                                                        {`${project.state} ${project.edited === project.created ? "created" : "edited"} ${formatDate(project.edited)}`}
                                                        {!isVersionCompatible && (
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                Version
                                                                incompatible (
                                                                {
                                                                    project.version
                                                                }{" "}
                                                                &lt;{" "}
                                                                {
                                                                    project.minVersion
                                                                }
                                                                )
                                                            </Typography>
                                                        )}
                                                    </>
                                                }
                                                primaryTypographyProps={{
                                                    variant: "h6",
                                                    noWrap: true,
                                                }}
                                                secondaryTypographyProps={{
                                                    variant: "body2",
                                                    color: "text.secondary",
                                                }}
                                                sx={{ flex: "1 1 auto" }}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                </Paper>
                            );
                        })}
                    </List>
                )}

                <Fab
                    color="primary"
                    aria-label="add"
                    sx={{ position: "fixed", bottom: 16, right: 16 }}
                    onClick={handleAddProjectClick}
                    disabled={creating}
                >
                    <AddIcon />
                </Fab>

                {creating && (
                    <Box sx={{ position: "fixed", bottom: 80, right: 16 }}>
                        <CircularProgress size={24} />
                    </Box>
                )}

                <Snackbar
                    open={showSnackbar}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                    <Alert
                        onClose={handleCloseSnackbar}
                        severity={error ? "error" : "success"}
                        sx={{ width: "100%" }}
                    >
                        {error || snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </>
    );
};
