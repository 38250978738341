import { advertisingImport } from "./supported-import-types/advertising-import-types";
import { analyticsImport } from "./supported-import-types/analytics-import-types";
import { competitorImport } from "./supported-import-types/competitor-import-types";
import { contentPerformanceImport } from "./supported-import-types/content-performance-import-types";
import { crmImport } from "./supported-import-types/crm-import-types";
import { demographicsImport } from "./supported-import-types/demographics-import-types";
import { emailMarketingImport } from "./supported-import-types/email-marketing-import-types";
import { feedbackImport } from "./supported-import-types/feedback-import-types";
import { geolocationImport } from "./supported-import-types/geolocation-import-types";
import { keywordImport } from "./supported-import-types/keyword-import-types";
import { marketResearchImport } from "./supported-import-types/market-research-import-types";
import { productImport } from "./supported-import-types/product-import-types";
import { salesChannelImport } from "./supported-import-types/sales-channel-import-types";
import { salesImport } from "./supported-import-types/sales-import-types";
import { socialMediaImport } from "./supported-import-types/social-media-import-types";
import { stockImport } from "./supported-import-types/stock-import-types";

type DataImportColumn = {
    name: string;
    type?: 'string' | 'number' | 'date';
    description: string;
    alternativeNames?: readonly string[];
};

export type SupportedAnalysis = {
    name: string;
    description: string;
    details?: string;
    requiresTimeSeries: boolean;
    requiredColumns: readonly DataImportColumn[];
    optionalColumns?: readonly DataImportColumn[];
};

export type SupportedImportType = {
    value: string; // FIXME should be 'id'
    label: string;
    description: string;
    analyses: readonly SupportedAnalysis[];
};

const otherImport: SupportedImportType = {
    value: "other",
    label: "Something else",
    description: "",
    analyses: [],
};

export function getSupportedDataImportTypes(): SupportedImportType[] {
    return [
        salesImport,
        productImport,
        stockImport,
        analyticsImport,
        keywordImport,
        demographicsImport,
        advertisingImport,
        socialMediaImport,
        feedbackImport,
        emailMarketingImport,
        crmImport,
        competitorImport,
        marketResearchImport,
        contentPerformanceImport,
        geolocationImport,
        salesChannelImport,
        otherImport,
    ];
}
