// Login.tsx

import * as React from 'react';
import { useState, useEffect } from 'react';
import { connection } from './auth-context';
import {
  Button,
  Container,
  Box,
  TextField,
  Typography,
  Alert,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

const nameRegex = /^[a-zA-Z0-9 _-]{3,30}$/;

const validateName = (input: string): boolean => nameRegex.test(input);

export const Login: React.FC = () => {
  const [businessName, setBusinessName] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const navigate = useNavigate();

  // Load saved businessName and userName from localStorage on component mount
  useEffect(() => {
    const savedBusinessName = localStorage.getItem('businessName');
    const savedUserName = localStorage.getItem('userName');

    if (savedBusinessName && savedUserName) {
      setBusinessName(savedBusinessName);
      setUserName(savedUserName);
      setRememberMe(true);
    }
  }, []);

  // Handle changes to the "Remember Me" toggle
  useEffect(() => {
    if (rememberMe) {
      // Save current businessName and userName to localStorage
      if (businessName.trim() && userName.trim()) {
        localStorage.setItem('businessName', businessName.trim());
        localStorage.setItem('userName', userName.trim());
      }
    } else {
      // Clear saved businessName and userName from localStorage
      localStorage.removeItem('businessName');
      localStorage.removeItem('userName');
    }
  }, [rememberMe, businessName, userName]);

  const handleLogin = async () => {
    setError(null);

    // Client-side validation
    if (!businessName.trim()) {
      setError('Business name is required.');
      return;
    }
    if (!validateName(businessName.trim())) {
      setError(
        "Invalid business name. Must be only letters, numbers, spaces, underscores, or hyphens and between 3 and 30 characters."
      );
      return;
    }

    if (!userName.trim()) {
      setError('User name is required.');
      return;
    }
    if (!validateName(userName.trim())) {
      setError(
        "Invalid user name. Must be only letters, numbers, spaces, underscores, or hyphens and between 3 and 30 characters."
      );
      return;
    }

    if (!password) {
      setError('Password is required.');
      return;
    }

    setIsSubmitting(true);

    try {
      await connection.login(businessName.trim(), userName.trim(), password);
      
      // If "Remember Me" is checked, save the credentials
      if (rememberMe) {
        localStorage.setItem('businessName', businessName.trim());
        localStorage.setItem('userName', userName.trim());
      } else {
        // Ensure credentials are not saved
        localStorage.removeItem('businessName');
        localStorage.removeItem('userName');
      }

      navigate('/');
    } catch (err: any) {
      console.error(err);
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('Invalid credentials. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleRememberMeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          padding: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: 3,
          borderRadius: 2,
          backgroundColor: '#fafafa',
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
          noValidate
          sx={{ mt: 3 }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            id="business-name"
            name="organization"
            label="Business Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            required
            autoComplete="organization"
            error={Boolean(
              error && error.toLowerCase().includes('business name')
            )}
            helperText={
              error && error.toLowerCase().includes('business name')
                ? error
                : ' '
            }
          />
          <TextField
            id="user-name"
            name="username"
            label="User Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
            autoComplete="username"
            error={Boolean(
              error && error.toLowerCase().includes('user name')
            )}
            helperText={
              error && error.toLowerCase().includes('user name') ? error : ' '
            }
          />
          <TextField
            id="password"
            name="current-password"
            label="Password"
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            autoComplete="current-password"
            error={Boolean(
              error && error.toLowerCase().includes('password')
            )}
            helperText={
              error && error.toLowerCase().includes('password') ? error : ' '
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword ? 'Hide password' : 'Show password'
                    }
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={rememberMe}
                onChange={handleRememberMeChange}
                color="primary"
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
            sx={{ mt: 3, mb: 2 }}
          >
            {isSubmitting ? 'Logging in...' : 'Login'}
          </Button>
          <Box mt={2} textAlign="center">
            <Typography variant="body2">
              Don't have an account?{' '}
              <Link
                to="/signup"
                style={{ textDecoration: 'none', color: '#1976d2' }}
              >
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
