import { SupportedImportType } from "../supported-data-import-types";

export const geolocationImport: SupportedImportType = {
    value: "geolocation",
    label: "Geolocation Data",
    description: "Insights into where customers are geographically located, enabling location-based marketing strategies and personalized campaigns.",
    analyses: [
        {
            description: "Geographical heat maps",
            name: "feedbackGeographicalHeatMaps",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "location",
                    description: "Location of the customer",
                },
                {
                    name: "numberOfCustomers",
                    description: "Number of customers in the location",
                },
            ],
        },
        {
            description: "Sales performance by region",
            name: "feedbackSalesPerformanceByRegion",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "location",
                    description: "Location of the customer",
                },
                {
                    name: "salesVolume",
                    description: "Sales volume in the location",
                },
            ],
        },
        {
            description: "Target market segmentation",
            name: "feedbackTargetMarketSegmentation",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "location",
                    description: "Location of the customer",
                },
                {
                    name: "numberOfCustomers",
                    description: "Number of customers in the location",
                },
            ],
        },
    ],
};
