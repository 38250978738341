import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode,
    useCallback,
    useMemo,
} from "react";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { getAPIClient } from "./client-id";
import { useSnackbar } from "./snackbar-context";
import { CircularProgress } from "@mui/material";

interface UserDetails {
    userName: string;
    businessName: string;
    userDisplayName: string;
    businessDisplayName: string;
    businessInfoChatId?: string;
}

interface Business {
    completedSteps?: number[];
}

interface UserContextProps {
    // read only
    userName: string;
    businessName: string;

    // read write
    userDisplayName: string;
    businessDisplayName: string;
    businessInfoChatId?: string;
    businessSetupCompletedSteps: Set<number>;

    setUserDisplayName: (userDisplayName: string) => void;
    setBusinessDisplayName: (businessDisplayName: string) => void;
    setBusinessInfoChatId: (businessInfoChatId: string) => void;
    setBusinessSetupCompletedSteps: (completedSteps: Set<number>) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

function areSetsEqual<T>(set1: Set<T>, set2: Set<T>): boolean {
    if (set1.size !== set2.size) {
        return false;
    }

    return Array.from(set1).every((value) => set2.has(value));
}

export const UserProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    console.log("UserProvider rendering");

    const navigate = useNavigate();
    const navigateLogon = useCallback(() => navigate("/login"), [navigate]);

    const { showSnackbar } = useSnackbar();

    // const [loading, setLoading] = useState<boolean>(true);

    const [userName, setUserName] = useState<string>("");
    const [businessName, setBusinessName] = useState<string>("");
    const [userDisplayName, setUserDisplayNameState] = useState<string>("");
    const [businessDisplayName, setBusinessDisplayNameState] =
        useState<string>("");
    const [businessInfoChatId, setBusinessInfoChatIdState] =
        useState<string>("");
    const [businessSetupCompletedSteps, setBusinessSetupCompletedStepsState] =
        useState<Set<number>>(new Set<number>());

    console.log("UserProvider userDetails:", {
        userName,
        businessName,
        userDisplayName,
        businessDisplayName,
        businessInfoChatId,
    });

    const patchBusiness = async (
        update: Partial<{ displayName: string; infoChatId: string }>,
    ) => {
        console.log("patchBusiness", update);
        await getAPIClient().patch("/api/v1/business", update);
    };

    const patchUser = async (update: Partial<{ displayName: string }>) => {
        console.log("patchUser", update);
        await getAPIClient().patch("/api/v1/user", update);
    };

    const setUserDisplayName = useCallback(
        async (newUserDisplayName: string) => {
            if (newUserDisplayName === userDisplayName) {
                return;
            }

            console.log("setUserDisplayName", newUserDisplayName);
            try {
                await patchUser({ displayName: newUserDisplayName });
                setBusinessDisplayNameState(newUserDisplayName);
            } catch (error) {
                showSnackbar("Failed to update user display name", "error");
            }
        },
        [userDisplayName, showSnackbar],
    );

    const setBusinessDisplayName = useCallback(
        async (newBusinessDisplayName: string) => {
            if (newBusinessDisplayName === businessDisplayName) {
                return;
            }

            console.log("setBusinessDisplayName", newBusinessDisplayName);
            try {
                await patchBusiness({ displayName: newBusinessDisplayName });
                setBusinessDisplayNameState(newBusinessDisplayName);
            } catch (error) {
                showSnackbar("Failed to update business display name", "error");
            }
        },
        [businessDisplayName, showSnackbar],
    );

    const setBusinessInfoChatId = async (newBusinessInfoChatId: string) => {
        if (newBusinessInfoChatId === businessInfoChatId) {
            return;
        }

        console.log("setBusinessInfoChatId", newBusinessInfoChatId);
        try {
            await patchBusiness({ infoChatId: newBusinessInfoChatId });
            setBusinessDisplayNameState(newBusinessInfoChatId);
        } catch (error) {
            showSnackbar("Failed to update business info chat ID", "error");
        }
    };

    const setBusinessSetupCompletedSteps = async (
        newCompletedSteps: Set<number>,
    ) => {
        if (areSetsEqual(newCompletedSteps, businessSetupCompletedSteps)) {
            return;
        }
    };

    useEffect(() => {
        console.log("UserProvider useEffect will fetch user details...");

        const fetchUserDetails = async () => {
            console.log("will GET /api/v1/user");

            try {
                const response = await getAPIClient().get("/api/v1/user");

                console.log("GET /api/v1/user response:", response.data);

                console.log(
                    `'${response.data.userName}' updates '${userName}' = ${response.data.userName != userName}`,
                );
                console.log(
                    `'${response.data.businessName}' updates '${businessName}' = ${response.data.businessName != businessName}`,
                );
                console.log(
                    `'${response.data.userDisplayName}' updates '${userDisplayName}' = ${response.data.userDisplayName != userDisplayName}`,
                );
                console.log(
                    `'${response.data.businessDisplayName}' updates '${businessDisplayName}' = ${response.data.businessDisplayName != businessDisplayName}`,
                );
                console.log(
                    `'${response.data.businessInfoChatId}' updates '${businessInfoChatId}' = ${response.data.businessInfoChatId != businessInfoChatId}`,
                );

                if (response.data.userName != userName) {
                    setUserName(response.data.userName);
                }

                if (response.data.businessName != businessName) {
                    setBusinessName(response.data.businessName);
                }

                if (response.data.userDisplayName != userDisplayName) {
                    setUserDisplayNameState(response.data.userDisplayName);
                }

                if (response.data.businessDisplayName != businessDisplayName) {
                    setBusinessDisplayNameState(
                        response.data.businessDisplayName,
                    );
                }

                if (response.data.businessInfoChatId != businessInfoChatId) {
                    setBusinessInfoChatIdState(
                        response.data.businessInfoChatId,
                    );
                }

                const newBusinessSetupCompletedSteps = new Set<number>(
                    response.data.businessSetupCompletedSteps,
                );

                if (
                    !areSetsEqual(
                        newBusinessSetupCompletedSteps,
                        businessSetupCompletedSteps,
                    )
                ) {
                    setBusinessSetupCompletedStepsState(
                        newBusinessSetupCompletedSteps,
                    );
                }

                console.log("User details fetched:", response.data);
            } catch (err) {
                const error = err as AxiosError;
                if (error.response?.status === 401) {
                    showSnackbar("Not authenticated", "error");

                    navigateLogon();
                } else {
                    showSnackbar("Failed to fetch user details", "error");
                }
            } finally {
                console.log("Setting loading to false");

                // setLoading(false);
            }
        };

        fetchUserDetails();
    }, [navigateLogon, showSnackbar]);

    // console.log('UserProvider returning markup with loading state:', loading);

    const memoizedValue = useMemo(
        () => ({
            userName,
            businessName,
            userDisplayName,
            businessDisplayName,
            businessInfoChatId,
            businessSetupCompletedSteps,
            setUserDisplayName,
            setBusinessDisplayName,
            setBusinessInfoChatId,
            setBusinessSetupCompletedSteps,
        }),
        [
            userName,
            businessName,
            userDisplayName,
            businessDisplayName,
            businessInfoChatId,
            businessSetupCompletedSteps,
            setUserDisplayName,
            setBusinessDisplayName,
            setBusinessInfoChatId,
            setBusinessSetupCompletedSteps,
        ],
    );

    return !userName ? (
        <CircularProgress />
    ) : (
        <UserContext.Provider value={memoizedValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextProps => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};
