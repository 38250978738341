import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Typography } from '@mui/material';


type AnalysisSectionProps = {
    analysis: string | null;
};

const AnalysisSection: React.FC<AnalysisSectionProps> = ({ analysis }) => {
    return (
        <Box>
            {analysis ? (
                <ReactMarkdown>{analysis}</ReactMarkdown>
            ) : (
                <Typography>No analysis available.</Typography>
            )}
        </Box>
    );
};

export default AnalysisSection;
