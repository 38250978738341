import { SupportedImportType } from "../supported-data-import-types";

export const emailMarketingImport: SupportedImportType = {
    value: "email_marketing",
    label: "Email Marketing Data",
    description: "Metrics such as open rates, click-through rates, unsubscribe rates, and conversion rates from email campaigns.",
    analyses: [
        {
            description: "Performance analysis of email campaigns",
            name: "emailPerformanceAnalysisOfEmailCampaigns",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "campaignName",
                    description: "Name of the email campaign",
                },
                {
                    name: "openRate",
                    description: "Open rate of the email",
                },
                {
                    name: "clickThroughRate",
                    description: "Click-through rate of the email",
                },
            ],
        },
        {
            description: "A/B testing results on subject lines or content",
            name: "emailAbTestingResultsOnSubjectLinesOrContent",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "campaignName",
                    description: "Name of the email campaign",
                },
                {
                    name: "openRate",
                    description: "Open rate of the email",
                },
                {
                    name: "clickThroughRate",
                    description: "Click-through rate of the email",
                },
            ],
        },
        {
            description: "Trends in open and click rates over time",
            name: "emailTrendsInOpenAndClickRatesOverTime",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "campaignName",
                    description: "Name of the email campaign",
                },
                {
                    name: "openRate",
                    description: "Open rate of the email",
                },
                {
                    name: "clickThroughRate",
                    description: "Click-through rate of the email",
                },
            ],
        },
    ],
};
