// BusinessInfo.tsx

import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import Chatbot from "./chatbot";
import { UserProvider, useUser } from "./user-provider";
import { Link as RouterLink } from 'react-router-dom';
import BusinessSetupStepper from './business-setup-stepper';

export const BusinessInfo: React.FC = () => {
    return (
        <>
        <UserProvider>
            <BusinessInfoInner />
        </UserProvider>
        <BusinessSetupStepper activeStep={0} />
        </>
    );
};

const BusinessInfoInner: React.FC = () => {
    const {
        userDisplayName,
        businessDisplayName,
        businessInfoChatId,
        businessSetupCompletedSteps,
        setBusinessInfoChatId,
        setBusinessSetupCompletedSteps
    } = useUser();

    const [isChatFinished, setIsChatFinished] = useState<boolean>(false);
    
    const [chatId, setChatId] = useState<string | null>(
        businessInfoChatId || null,
    );

    // Callback to handle chatId when chat starts
    const handleChatStarted = (newChatId: string) => {
        setChatId(newChatId);

        setBusinessInfoChatId(newChatId);
    };

    const handleChatFinished = (isFinished: boolean) => {
        setIsChatFinished(isFinished);

        if (isFinished) {
            setBusinessSetupCompletedSteps(
                new Set([...businessSetupCompletedSteps, 0]),
            );
        }
    }

    return (
        <Box sx={{ p: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {`Welcome, ${userDisplayName} from ${businessDisplayName}`}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Chatbot
                        persona="businessInfo"
                        userMemoriesName="Business Memories"
                        chatId={chatId}
                        onChatStarted={handleChatStarted}
                        onFinished={handleChatFinished}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
