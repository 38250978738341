import * as React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { 
    Card, 
    CardContent, 
} from '@mui/material';

import { InterestOverTimeStatistics, MudlarkInterestOverTimeData } from '@mudlark/common';
import { memo } from 'react';

ChartJS.register(
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend
);

interface TimeSeriesChartProps {
    data: MudlarkInterestOverTimeData;
    descriptions: { [keyword: string]: string };
}

const isTopic = (keyword: string) => keyword.startsWith('/g/') || keyword.startsWith('/m/');

const getKeywordsLabels = (keywords: string[], descriptions: { [key: string]: string }) => {
    const keywordDescriptionsCounts: {[description: string]: number} = {};

    for (const keyword of keywords) {
        const description = descriptions[keyword] ?? keyword;        

        keywordDescriptionsCounts[description] = (keywordDescriptionsCounts[description] ?? 0) + 1;
    }

    const keywordLabels: {[keyword: string]: string} =
        // default to descriptions[keyword] ?? keyword
        Object.fromEntries(keywords.map((keyword) => [keyword, descriptions[keyword] ?? keyword]));

    for (const keyword of keywords) {
        const description = descriptions[keyword] ?? keyword;
        const count = keywordDescriptionsCounts[description];

        if (count == 1) {
            keywordLabels[keyword] = description;
        } else if(isTopic(keyword)) {
            keywordLabels[keyword] = `${description} (topic)`;
        } else {
            keywordLabels[keyword] = `${description} (term)`;
        }
    }

    return keywords.map((keyword) => keywordLabels[keyword]);
}

const TimeSeriesChart: React.FC<TimeSeriesChartProps> = ({ data, descriptions }) => {
    const keywordLabels = getKeywordsLabels(data.keywords, descriptions);

    // console.log(`mapped keyword labels: ${keywordLabels}`);
    // console.log(`from data.keywords: ${data.keywords}`);
    // console.log(`from descriptions: ${JSON.stringify(descriptions)}`);

    const chartData = {
        labels: data.times.map(time => new Date(time).toLocaleDateString()),
        datasets: data.keywords.map((_keyword, index) => ({
            label: keywordLabels[index],
            data: data.data[index],
            fill: false,
            borderColor: `hsl(${(index * 360) / data.keywords.length}, 100%, 50%)`,
            tension: 0.1,
        })),
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
                labels: {
                    usePointStyle: true,  // Use point style instead of a rectangle
                    pointStyle: 'line',   // Render the label as a line
                },
            },
            title: {
                display: true,
                text: 'Interest Over Time',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Interest',
                },
            },
        },
    };

    return (
        <Card data-testid="time-series-chart">
            <CardContent>
                <Line data={chartData} options={options} />
            </CardContent>
        </Card>
    );
};

export default memo(TimeSeriesChart);
