import React, { createContext, useContext, useEffect, useState } from 'react';
import { useIsAuthenticated } from './auth-context';
import { getAPIClient } from './client-id';

// Create a context for the workflow ID
const WorkflowIdContext = createContext<string | null>(null);

interface WorkflowIdProviderProps {
    workflowId?: string; // Optional initial workflow ID
    children: React.ReactNode; // Children components that need the workflow ID
}

// ensures children components have access to the workflow ID
// and that the workflow ID is valid before rendering the children
// will create a new workflow if no workflow ID is provided
export const WorkflowIdProvider: React.FC<WorkflowIdProviderProps> = ({ workflowId, children }) => {
    const [currentWorkflowId, setCurrentWorkflowId] = useState<string | null>(workflowId || null);
    const [isWorkflowValid, setIsWorkflowValid] = useState<boolean>(false);
    
    const isAuthenticated = useIsAuthenticated(); // Check authentication

    console.log(`WorkflowIdProvider current workflow ID: ${currentWorkflowId} of type ${typeof currentWorkflowId}`);

    // Function to create a new workflow via POST
    const createWorkflow = async () => {
        try {
            const response = await getAPIClient().post<{ workflowId: string }>('/api/v1/workflow', {});

            console.log('Created new workflow with ID:', response.data.workflowId);

            return response.data.workflowId;
        } catch (error) {
            console.error('Error creating workflow:', error);
            return null;
        }
    };

    // Function to check if the workflow exists and is valid
    const checkWorkflowExists = async (workflowId: string) => {
        try {
            const response = await getAPIClient().get<{ exists: boolean }>(`/api/v1/workflow/${workflowId}/status`);
            return response.data.exists;
        } catch (error) {
            console.error('Error checking workflow status:', error);
            return false;
        }
    };

    useEffect(() => {
        const initializeWorkflow = async () => {
            console.log('Initializing workflow...');

            let id = currentWorkflowId;

            // If no workflow ID is provided, create a new workflow
            if (!id) {
                console.log('Creating new workflow...');

                id = await createWorkflow();

                console.log('Created new workflow with ID:', id);

                if (id) setCurrentWorkflowId(id);
            } else {
                console.log(`Using existing workflow with ID ${id} where ID is of type ${typeof id}`);
            }

            // Poll the server every 500 ms until the workflow is valid
            if (id) {
                console.log('Will check workflow status for ID:', id);

                const pollWorkflow = async () => {
                    const exists = await checkWorkflowExists(id);

                    if (exists) {
                        console.log(`Workflow ${id} exists and is valid`);
                        setIsWorkflowValid(true);
                    } else {
                        console.log(`Workflow ${id} does not exist yet`);
                        setTimeout(pollWorkflow, 500); // Poll again in 500 ms

                        // FIXME limit retry attempts, show snackbar error
                    }
                };
                pollWorkflow();
            }
        };

        initializeWorkflow();
    }, [currentWorkflowId]);

    // Block rendering if the user is not authenticated
    if (!isAuthenticated) {
        return <div>You need to be logged in to access this page.</div>;
    }

    // Block rendering if the workflow is invalid
    if (!isWorkflowValid) {
        return <div>Loading workflow...</div>;
    }

    console.log('Have valid workflow, workflow ID is', currentWorkflowId);

    return (
        <WorkflowIdContext.Provider value={currentWorkflowId}>
            {children}
        </WorkflowIdContext.Provider>
    );
};

// Custom hook to use the workflow ID in child components
export const useWorkflowId = () => {
    const context = useContext(WorkflowIdContext);
    if (!context) {
        throw new Error('useWorkflowId must be used within a WorkflowIdProvider');
    }
    return context;
};
