// SignupForm.tsx

import React, { useState, ChangeEvent, FormEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, connection } from './auth-context';
import {
    TextField,
    Button,
    Grid,
    Typography,
    Container,
    Box,
    Snackbar,
    Alert,
    AlertColor,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    RadioGroup,
    FormControlLabel,
    Radio,
    ListItemText,
    SelectChangeEvent,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getAPIClient } from './client-id';

// Utility functions for validation
const validateUsername = (username: string): boolean =>
    /^[a-zA-Z0-9_-]{3,30}$/.test(username);
const validateBusinessName = (businessName: string): boolean =>
    /^[a-zA-Z0-9_-]{3,30}$/.test(businessName);
const validatePassword = (password: string): boolean =>
    password.trim().length >= 12;

// Interfaces for form data and error states
interface FormData {
    appKey: string;
    businessName: string;
    businessDisplayName: string;
    userName: string;
    userDisplayName: string;
    password: string;
    confirmPassword: string;
}

interface ErrorState {
    [field: string]: string;
}

interface SnackbarState {
    open: boolean;
    message: string;
    severity: AlertColor;
}

interface Business {
    businessName: string;
    displayName: string;
}

export const Signup: React.FC = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    // Initialize form data state
    const [formData, setFormData] = useState<FormData>({
        appKey: '',
        businessName: '',
        businessDisplayName: '',
        userName: '',
        userDisplayName: '',
        password: '',
        confirmPassword: '',
    });

    // Initialize error state
    const [errors, setErrors] = useState<ErrorState>({});

    // Initialize submission state
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    // Initialize snackbar state
    const [snackbar, setSnackbar] = useState<SnackbarState>({
        open: false,
        message: '',
        severity: 'success',
    });

    // State for password visibility
    const [showAppKey, setShowAppKey] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

    // State for businesses
    const [businesses, setBusinesses] = useState<Business[]>([]);
    const [isFetchingBusinesses, setIsFetchingBusinesses] = useState<boolean>(false);
    const [fetchBusinessesError, setFetchBusinessesError] = useState<string>('');

    // State for form stages
    const [isAppKeyValid, setIsAppKeyValid] = useState<boolean | null>(null);
    const [isValidatingAppKey, setIsValidatingAppKey] = useState<boolean>(false);

    // State for business selection
    const BUSINESS_OPTION_NEW = 'new';
    const BUSINESS_OPTION_EXISTING = 'existing';
    const [selectedBusinessOption, setSelectedBusinessOption] = useState<string>(''); // Changed from BUSINESS_OPTION_NEW

    // Debounce timer
    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(null);

    // Handlers to toggle password visibility
    const handleToggleAppKeyVisibility = () => {
        setShowAppKey((prev) => !prev);
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword((prev) => !prev);
    };

    // Handle change for input fields
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
        child?: React.ReactNode
    ) => {
        const { name, value } = e.target;

        if (!name) {
            return;
        }

        // Update form data
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));

        // Reset error for the current field
        setErrors((prev) => ({
            ...prev,
            [name]: '',
        }));

        // If appKey changes, reset validation and related states
        if (name === 'appKey') {
            setIsAppKeyValid(null);
            setSelectedBusinessOption('');
            setBusinesses([]);
            setFetchBusinessesError('');
            if (debounceTimer) {
                clearTimeout(debounceTimer);
            }
            // Debounce API key validation
            const timer = setTimeout(() => {
                validateAppKey(value as string);
            }, 500); // 500ms debounce
            setDebounceTimer(timer);
        }
    };

    // Function to validate App Key
    const validateAppKey = async (appKey: string) => {
        if (appKey.trim() === '') {
            setIsAppKeyValid(false);
            setFetchBusinessesError('App Key is required.');
            return;
        }

        setIsValidatingAppKey(true);
        setFetchBusinessesError('');
        try {
            const response = await getAPIClient().get('/api/v1/auth/businesses', {
                headers: {
                    'X-App-Key': appKey,
                },
            });

            // Assuming the API returns an array of businesses
            const fetchedBusinesses: Business[] = response.data.businesses;

            // Sort businesses alphabetically by displayName
            fetchedBusinesses.sort((a, b) => a.displayName.localeCompare(b.displayName));

            setBusinesses(fetchedBusinesses);
            setIsAppKeyValid(true);
            setSelectedBusinessOption('');
        } catch (error: any) {
            console.error('Error validating App Key:', error.message);
            setIsAppKeyValid(false);
            setFetchBusinessesError(
                error.response?.data?.message || 'Invalid App Key.'
            );
            setBusinesses([]);
        } finally {
            setIsValidatingAppKey(false);
        }
    };

    // Validate form data
    const validate = (): boolean => {
        const newErrors: ErrorState = {};

        // Check for required fields
        if (!formData.appKey.trim()) {
            newErrors.appKey = 'This field is required.';
        }

        if (selectedBusinessOption === BUSINESS_OPTION_NEW) {
            // Validate new business fields
            if (!formData.businessName.trim()) {
                newErrors.businessName = 'This field is required.';
            } else if (!validateBusinessName(formData.businessName)) {
                newErrors.businessName =
                    'Invalid business name. Use 3-30 characters: letters, numbers, underscores, hyphens.';
            }

            if (!formData.businessDisplayName.trim()) {
                newErrors.businessDisplayName = 'This field is required.';
            }
        } else if (selectedBusinessOption === BUSINESS_OPTION_EXISTING) {
            if (!formData.businessName.trim()) {
                newErrors.businessName = 'Please select a business.';
            }
        }

        // Common validations
        if (!formData.userName.trim()) {
            newErrors.userName = 'This field is required.';
        } else if (!validateUsername(formData.userName)) {
            newErrors.userName =
                'Invalid username. Use 3-30 characters: letters, numbers, underscores, hyphens.';
        }

        if (!formData.userDisplayName.trim()) {
            newErrors.userDisplayName = 'This field is required.';
        }

        if (!formData.password.trim()) {
            newErrors.password = 'This field is required.';
        } else if (!validatePassword(formData.password)) {
            newErrors.password =
                'Password must be at least 12 characters long.';
        }

        if (!formData.confirmPassword.trim()) {
            newErrors.confirmPassword = 'This field is required.';
        } else if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords do not match.';
        }

        setErrors(newErrors);

        // Return true if no errors
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validate()) {
            return;
        }

        setIsSubmitting(true);

        try {
            // Prepare payload
            const payload: any = {
                appKey: formData.appKey.trim(),
                userName: formData.userName.trim(),
                userDisplayName: formData.userDisplayName.trim(),
                password: formData.password.trim(),
                businessOption: selectedBusinessOption, // Added businessOption
            };

            if (selectedBusinessOption === BUSINESS_OPTION_NEW) {
                payload.businessName = formData.businessName.trim();
                payload.businessDisplayName = formData.businessDisplayName.trim();
            } else {
                payload.businessName = formData.businessName.trim();
            }

            // Send POST request to sign-up endpoint
            const response = await getAPIClient().post('/api/v1/auth/signup', payload, {
                headers: {
                    'X-App-Key': payload.appKey,
                },
                withCredentials: true, // Include HttpOnly cookies
            });

            // Handle successful sign-up
            setSnackbar({
                open: true,
                message: 'Registration successful! You are now logged in.',
                severity: 'success',
            });

            console.log('Sign-up successful:', response.data);

            // Store authentication status in localStorage
            localStorage.setItem('isAuthenticated', 'true');

            (async () => {
                await connection.signedUp();

                navigate('/');
            })();

            // setTimeout(
            //     () => {
            //         window.location.href = '/'
            //     },
            //     500
            // );
        } catch (error: any) {
            console.error('Sign-up error:', error.message);

            // Extract error message from server response
            const errorMessage =
                error.response?.data?.message || 'An error occurred during registration.';

            setSnackbar({
                open: true,
                message: errorMessage,
                severity: 'error',
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Handle snackbar close event
    const handleCloseSnackbar = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbar((prev) => ({
            ...prev,
            open: false,
        }));
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    marginTop: 8,
                    padding: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    boxShadow: 3,
                    borderRadius: 2,
                    backgroundColor: '#fafafa',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign Up
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{ mt: 3, width: '100%' }}
                >
                    {/* Stage 1: App Key Entry */}
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="appKey"
                                label="App Key"
                                name="appKey"
                                type={showAppKey ? 'text' : 'password'}
                                autoComplete="off"
                                value={formData.appKey}
                                onChange={handleChange}
                                error={Boolean(errors.appKey) || (isAppKeyValid === false)}
                                helperText={
                                    errors.appKey ||
                                    (isAppKeyValid === false ? fetchBusinessesError : '')
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={showAppKey ? 'Hide App Key' : 'Show App Key'}
                                                onClick={handleToggleAppKeyVisibility}
                                                edge="end"
                                            >
                                                {showAppKey ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {isValidatingAppKey && (
                                <Box display="flex" alignItems="center" mt={1}>
                                    <CircularProgress size={20} />
                                    <Typography sx={{ ml: 1 }}>Validating App Key...</Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    {/* Show the rest of the form only if App Key is valid */}
                    {isAppKeyValid && (
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            {/* Business Option Toggle */}
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        row
                                        aria-label="business-option"
                                        name="businessOption"
                                        value={selectedBusinessOption}
                                        onChange={(e) => {
                                            setSelectedBusinessOption(e.target.value);
                                            setFormData((prev) => ({
                                                ...prev,
                                                businessName: '',
                                                businessDisplayName: '',
                                            }));
                                            setErrors((prev) => ({
                                                ...prev,
                                                businessName: '',
                                                businessDisplayName: '',
                                            }));
                                        }}
                                    >
                                        <FormControlLabel
                                            value={BUSINESS_OPTION_EXISTING} // Moved "Use Existing Business" first
                                            control={<Radio />}
                                            label="Use Existing Business" // Changed label
                                        />
                                        <FormControlLabel
                                            value={BUSINESS_OPTION_NEW}
                                            control={<Radio />}
                                            label="Create New Business"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {/* Conditionally render fields after business option is selected */}
                            {selectedBusinessOption && (
                                <>
                                    {/* Existing Business Selection */}
                                    {selectedBusinessOption === BUSINESS_OPTION_EXISTING && (
                                        <Grid item xs={12}>
                                            <FormControl fullWidth required>
                                                <InputLabel id="business-select-label">Business</InputLabel>
                                                <Select
                                                    labelId="business-select-label"
                                                    id="existingBusinessSelect"
                                                    name="businessName"
                                                    value={formData.businessName}
                                                    label="Business"
                                                    onChange={handleChange}
                                                    error={Boolean(errors.businessName)}
                                                >
                                                    {businesses.map((business) => (
                                                        <MenuItem
                                                            key={business.businessName}
                                                            value={business.businessName}
                                                        >
                                                            <ListItemText
                                                                primary={business.displayName}
                                                                secondary={business.businessName}
                                                            />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {errors.businessName && (
                                                    <Typography color="error" variant="caption">
                                                        {errors.businessName}
                                                    </Typography>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    )}

                                    {/* New Business Fields */}
                                    {selectedBusinessOption === BUSINESS_OPTION_NEW && (
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="newBusinessDisplayName"
                                                    label="Your Business Name"
                                                    name="businessDisplayName"
                                                    autoComplete="organization"
                                                    value={formData.businessDisplayName}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.businessDisplayName)}
                                                    helperText={
                                                        errors.businessDisplayName ||
                                                        "How the system will refer to the business, for example in chatbots, reports, and recommendations."
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    id="newBusinessName"
                                                    label="Short Business Name"
                                                    name="businessName"
                                                    autoComplete="organization"
                                                    value={formData.businessName}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.businessName)}
                                                    helperText={
                                                        errors.businessName ||
                                                        "A short name for the business, used when logging on. It must be unique across the system. Must not contain any spaces or special characters."
                                                    }
                                                />
                                            </Grid>
                                        </>
                                    )}

                                    {/* User Display Name */}
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="userDisplayName"
                                            label="Your Name"
                                            name="userDisplayName"
                                            autoComplete="given-name"
                                            value={formData.userDisplayName}
                                            onChange={handleChange}
                                            error={Boolean(errors.userDisplayName)}
                                            helperText={
                                                errors.userDisplayName ||
                                                "How the system will refer to you, for example in chatbots and reports."
                                            }
                                        />
                                    </Grid>
                                    {/* Username */}
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="userName"
                                            label="User Name"
                                            name="userName"
                                            autoComplete="username"
                                            value={formData.userName}
                                            onChange={handleChange}
                                            error={Boolean(errors.userName)}
                                            helperText={
                                                errors.userName ||
                                                "A short you'll use when logging on. It must be unique within the business. Must not contain any spaces or special characters."
                                            }
                                        />
                                    </Grid>
                                    {/* Password */}
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            autoComplete="new-password"
                                            value={formData.password}
                                            onChange={handleChange}
                                            error={Boolean(errors.password)}
                                            helperText={
                                                errors.password ||
                                                'At least 12 characters. May contain letters, numbers, spaces and special characters.'
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={showPassword ? 'Hide password' : 'Show password'}
                                                            onClick={handleTogglePasswordVisibility}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    {/* Confirm Password */}
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            id="confirmPassword"
                                            autoComplete="new-password"
                                            value={formData.confirmPassword}
                                            onChange={handleChange}
                                            error={Boolean(errors.confirmPassword)}
                                            helperText={errors.confirmPassword}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label={showConfirmPassword ? 'Hide confirm password' : 'Show confirm password'}
                                                            onClick={handleToggleConfirmPasswordVisibility}
                                                            edge="end"
                                                        >
                                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    )}

                    {/* Submit Button */}
                    {isAppKeyValid && selectedBusinessOption && (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={isSubmitting}
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {isSubmitting ? 'Registering...' : 'Sign Up'}
                        </Button>
                    )}
                </Box>
            </Box>
            {/* Snackbar for feedback */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Container>
    );
};
