import { SupportedImportType } from "../supported-data-import-types";

export const productImport: SupportedImportType = {
    value: "product",
    label: "Product Listings",
    description: "Detailed product descriptions, categories, pricing, SKUs, and product attributes (e.g., size, color).",
    analyses: [
        {
            description: "Price distribution (average, median)",
            name: "productPriceDistribution",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "price",
                    description: "Price of the product",
                    alternativeNames: [
                        "unit price",
                        "cost",
                        "product price",
                        "sale price",
                        "retail price",
                        "list price",
                        "amount",
                        "price per unit",
                        "selling price",
                    ],
                },
            ],
        },
        {
            description: "Inventory turnover rates",
            name: "productInventoryTurnoverRates",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "sku",
                    description: "Stock Keeping Unit",
                    alternativeNames: [
                        "SKU",
                        "sku number",
                        "product code",
                        "item number",
                        "item code",
                        "product id",
                        "stock code",
                        "article number",
                    ],
                },
                {
                    name: "stockStatus",
                    description: "Current stock status",
                    alternativeNames: [
                        "stock status",
                        "inventory status",
                        "availability",
                        "in stock",
                        "stock level",
                        "quantity available",
                        "stock quantity",
                        "quantity on hand",
                    ],
                },
            ],
        },
        {
            description: "Most popular categories",
            name: "productMostPopularCategories",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "category",
                    description: "Category of the product",
                    alternativeNames: [
                        "category name",
                        "product category",
                        "department",
                        "classification",
                        "group",
                    ],
                },
                {
                    name: "salesAmount",
                    description: "Amount of the sale",
                    alternativeNames: [
                        "amount",
                        "revenue",
                        "total",
                        "sale amount",
                        "transaction amount",
                        "order amount",
                        "amount sold",
                        "price",
                        "total price",
                    ],
                },
            ],
        },
        {
            description: "Price elasticity analysis",
            name: "productPriceElasticityAnalysis",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "price",
                    description: "Price of the product",
                    alternativeNames: [
                        "unit price",
                        "cost",
                        "product price",
                        "sale price",
                        "retail price",
                        "list price",
                        "amount",
                        "price per unit",
                        "selling price",
                    ],
                },
                {
                    name: "salesAmount",
                    description: "Amount of the sale",
                    alternativeNames: [
                        "amount",
                        "revenue",
                        "total",
                        "sale amount",
                        "transaction amount",
                        "order amount",
                        "amount sold",
                        "price",
                        "total price",
                    ],
                },
            ],
        },
    ],
};
