import { SupportedImportType } from "../supported-data-import-types";

export const salesChannelImport: SupportedImportType = {
    value: "sales_channel",
    label: "Sales Channel Data",
    description: "Performance metrics across different sales channels such as online stores, physical retail locations, and third-party marketplaces.",
    analyses: [
        {
            description: "Performance comparison across channels",
            name: "channelPerformanceComparisonAcrossChannels",
            requiresTimeSeries: false,
            requiredColumns: [
                {
                    name: "channelName",
                    description: "Name of the sales channel",
                },
                {
                    name: "revenue",
                    description: "Revenue generated from the channel",
                },
                {
                    name: "numberOfTransactions",
                    description: "Number of transactions in the channel",
                },
            ],
        },
        {
            description: "Trend analysis over time",
            name: "channelTrendAnalysisOverTime",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "channelName",
                    description: "Name of the sales channel",
                },
                {
                    name: "revenue",
                    description: "Revenue generated from the channel",
                },
                {
                    name: "numberOfTransactions",
                    description: "Number of transactions in the channel",
                },
            ],
        },
        {
            description: "Customer acquisition by channel",
            name: "channelCustomerAcquisitionByChannel",
            requiresTimeSeries: true,
            requiredColumns: [
                {
                    name: "channelName",
                    description: "Name of the sales channel",
                },
                {
                    name: "revenue",
                    description: "Revenue generated from the channel",
                },
                {
                    name: "numberOfTransactions",
                    description: "Number of transactions in the channel",
                },
            ],
        },
    ],
};
