import { Tooltip, IconButton, Typography, useMediaQuery, Box, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';

interface HelpTextProps {
    text: string;
    children: React.ReactNode;
    sx?: object;
    className?: string;
    style?: React.CSSProperties;
}

const HelpText: React.FC<HelpTextProps> = ({ text, children, sx, className, style }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const hasHover = useMediaQuery('(hover: hover)');

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isSmallScreen ? 'column' : 'row',
                alignItems: 'center',
                ...sx,
            }}
            className={className}
            style={style}
        >
            {children}
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: isSmallScreen ? 0 : theme.spacing(1),
                    marginTop: isSmallScreen ? theme.spacing(1) : 0,
                }}
            >
                {hasHover ? (
                    <Tooltip title={text} arrow>
                        <IconButton size="small">
                            <InfoIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <>
                        <IconButton size="small">
                            <InfoIcon fontSize="small" />
                        </IconButton>
                        <Typography
                            variant="body2"
                            sx={{ marginLeft: theme.spacing(1), fontSize: '12px' }}
                        >
                            {text}
                        </Typography>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default HelpText;
