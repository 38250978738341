// AuthContext.tsx

import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { clearClient, getAPIClient, getClientId } from './client-id';
import { CircularProgress } from '@mui/material';

// Define the shape of the connection object
export const connection: {
    login: (businessName: string, userName: string, password: string) => Promise<void>;
    signedUp: () => Promise<void>;
    logout: () => Promise<void>;
} = {
    login: async () => {},
    signedUp: async () => {},
    logout: async () => {},
};

// Define the shape of the authentication context
interface AuthContextProps {
    isAuthenticated: boolean;
}

// Create the authentication context
export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

// Custom hook for consuming the AuthContext
export const useIsAuthenticated = (): boolean => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useIsAuthenticated must be used within an AuthProvider');
    }

    return context.isAuthenticated;
};

const nameRegex = /^[a-zA-Z0-9 _-]{3,30}$/;

const validateName = (input: string): boolean => 
    nameRegex.test(input);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    console.log('AuthProvider rendering');

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        const storedAuthStatus = localStorage.getItem('isAuthenticated');
        return storedAuthStatus === 'true';
    });
    const [isLoading, setIsLoading] = useState<boolean>(() => !isAuthenticated); // Initialize isLoading to true only if not authenticated

    // Modify useEffect to run only once on mount
    useEffect(() => {
        // On component mount, check if the user is already authenticated
        const checkAuthentication = async () => {
            try {
                console.log('Checking authentication via /api/v1/auth');

                const response = await getAPIClient().get('/api/v1/auth', {
                    withCredentials: true,
                });

                if (response.status === 200 && response.data.clientId) {
                    console.log('User is authenticated');
                    setIsAuthenticated(true);
                } else {
                    console.log('User is not authenticated');
                    setIsAuthenticated(false);
                }
            } catch (error: any) {
                console.error('Authentication error:', error);
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuthentication();

        // Listen for storage events to sync auth status across tabs
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'isAuthenticated') {
                setIsAuthenticated(event.newValue === 'true');
            }
        };
        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []); // Empty dependency array ensures this runs only once

    useEffect(() => {
        // Update localStorage whenever isAuthenticated changes
        localStorage.setItem('isAuthenticated', isAuthenticated.toString());
    }, [isAuthenticated]);

    // Login function to authenticate the user
    const login = async (businessName: string, userName: string, password: string) => {
        console.log('Logging in with:', { businessName, userName });

        businessName = businessName.trim();
        userName = userName.trim();

        // Validate businessName and userName
        if (!validateName(businessName)) {
            throw new Error(
                "Invalid business name. Must be only letters, numbers or spaces and between 3 and 30 characters."
            );
        }

        if (!validateName(userName)) {
            throw new Error(
                "Invalid business name. Must be only letters, numbers or spaces and between 3 and 30 characters."
            );
        }

        if (!password) {
            throw new Error("Password is required.");
        }

        try {
            const clientId = localStorage.getItem('clientId');

            const payload = {
                businessName,
                userName,
                password,
                clientId, // Include clientId if necessary
            };

            const response = await getAPIClient().post(
                '/api/v1/auth/login',
                payload,
                { withCredentials: true }
            );

            if (response.status === 200) {
                console.log('Login successful');

                setIsAuthenticated(true);
                // localStorage will be updated via useEffect
            } else {
                console.log('Login failed:', JSON.stringify(response, null, 2));

                const errorData = response.data;
                throw new Error(errorData.message || 'Invalid login credentials');
            }
        } catch (error: any) {
            setIsAuthenticated(false);

            console.error('Login error:', error);
            throw error;
        }
    };

    connection.login = login;

    // The user has signed up and is pre-authenticated. We just need to record locally that they are authenticated.
    const signedUp = async () => {
        setIsAuthenticated(true);
    };

    connection.signedUp = signedUp;

    // Logout function to unauthenticate the user
    const logout = async () => {
        console.log('Logging out');

        try {            
            await getAPIClient().post('/api/v1/auth/logout');

        } catch (error: any) {
            console.error('Logout error:', error);
            // Optionally handle logout errors, e.g., show a notification
        } finally {
            clearClient();
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');

            console.log('Cleared authentication state');
        }
    };

    connection.logout = logout;

    console.log(`AuthProvider ${isAuthenticated ? 'authenticated' : 'unauthenticated'} for client ID: ${getClientId()}`);

    // Memoize the context value to prevent unnecessary re-renders
    const contextValue = React.useMemo(() => ({ isAuthenticated }), [isAuthenticated]);

    return (
        <AuthContext.Provider value={contextValue}>
            {isLoading ? <CircularProgress /> : children}
        </AuthContext.Provider>
    );
};
