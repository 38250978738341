import * as React from 'react';
import { memo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, GlobalStyles } from '@mui/material';
import { AuthProvider, AuthContext } from './auth-context';
import { Project } from './project';
import { Login } from './login';
import { Signup } from './signup';
import { HubLayout } from './hub-layout';
import { ProjectsList } from './projects-list';
import { BusinessInfo } from './business-info';
import { UserSettings } from './user-settings';
import { getAPIClient } from './client-id';
import { SSEProvider } from './sse-context';
import { SnackbarProvider } from './snackbar-context';
import BusinessIntegrations from './business-integrations';
import BusinessDataImport from './business-data-import';
import { Home } from './home';

// Step 1: Create a base theme to access default breakpoints
const baseTheme = createTheme();

const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    paddingLeft: baseTheme.spacing(1),
                    paddingRight: baseTheme.spacing(1),
                    [baseTheme.breakpoints.down('sm')]: {
                        paddingLeft: `5px`,
                        paddingRight: `5px`,
                    },
                },
            },
        },
    },
});

const CreateProject: React.FC = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        const createWorkflow = async () => {
            try {
                const response = await getAPIClient().post<{workflowId: string}>('/api/v1/workflow');
                const { workflowId } = response.data;

                setTimeout(() =>
                    navigate(`/project/${workflowId}`),
                    0
                );
            } catch (error) {
                console.error('Failed to create a new project:', error);
            }
        };

        createWorkflow();
    }, [navigate]);

    return <div>Creating a new project...</div>; // Optional: you can show a loading state here.
};

const App: React.FC = memo(() => {
    const authContext = React.useContext(AuthContext);

    if (!authContext) {
        return null; // You can return a loading state here if needed
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles
                styles={{
                    body: {
                        fontSize: '1rem',
                        [theme.breakpoints.down('sm')]: {
                            fontSize: '1.25rem',
                        },
                        [theme.breakpoints.up('md')]: {
                            fontSize: '1rem',
                        },
                    },
                    'input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 1000px transparent inset',
                        boxShadow: '0 0 0 1000px transparent inset',
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                }}
            />
            <Routes>
                {!authContext.isAuthenticated && (
                    <>
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="*" element={<Navigate to="/login" replace />} />
                    </>
                )}
                {authContext.isAuthenticated && (
                    <>
                        <Route path="/" element={<HubLayout />}>
                            <Route index element={<Home />} />
                            <Route path="business-info" element={<BusinessInfo />} />
                            <Route path="projects" element={<ProjectsList />} />
                            <Route path="project/:workflowId" element={<Project />} />
                            <Route path="archived" element={<div>Archived Projects</div>} />
                            <Route path="user-settings" element={<UserSettings />} />
                            <Route path="project" element={<CreateProject />} />
                            <Route path="business-integrations" element={<BusinessIntegrations />} />
                            <Route path="business-data-import" element={<BusinessDataImport />} />
                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Route>
                    </>
                )}
            </Routes>
        </ThemeProvider>
    );
});

const AuthenticatedApp: React.FC = () => (
    <AuthProvider>
        <Router>
            <SSEProvider>
                <SnackbarProvider>
                    <App />
                </SnackbarProvider>
            </SSEProvider>
        </Router>
    </AuthProvider>
);

export default AuthenticatedApp;
